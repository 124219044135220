/* .project-parent {
  padding: 25px;
  font-family: Montserrat;
  margin-bottom: 71px;
} */
.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.project-header h2 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.project-header button {
  padding: 15px 26px;
  border-radius: 4px;
  background: #214757;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.link,
.link:hover {
  padding: 15px 26px;
  border-radius: 4px;
  background: #214757;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.project-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
}

.project-sub-container {
  width: 24%;
  height: 100px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 26px 18px;
}

.project-sub-container p {
  color: #8d99a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.project-sub-container h4 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.invoice-container {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 25px;
}

.invoice-child {
  padding: 34px 18px;
  width: 24%;
  border-radius: 8px;
  background: #fff;
  position: relative;
}

.invoice-child::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 75px;
  background: #f6f6f6;
  left: 95%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.invoice-child:last-child::after {
  content: "";
  position: absolute;
  width: 0px;
}

.invoice-child p {
  color: #8d99a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding-bottom: 10px;
}

.invoice-child h4 {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  width: 100%;
}

.input-container {
  margin-right: 15px;
  width: 50%;
}

.input-container Input {
  border-radius: 4px;
  border: 1px solid rgba(205, 205, 205, 0.4);
  background: #fff;
  padding: 12px 40px 12px 12px;
  color: #214757;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
}

.select-tag.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  width: 170px;
  border-radius: 4px;
  height: 40px;
  padding: 5px 12px;
}

.select-status.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  width: 170px;
  border-radius: 4px;
  height: 40px;
  padding: 5px 12px;
}

.select-tag {
  margin-right: 15px;
}

/* .select-tag select,
.select-status select {
  border-radius: 4px;
  border: 1px solid rgba(205, 205, 205, 0.4);
  background: #fff;
  padding: 12px 40px 12px 12px;
  color: #214757;
}
.select-tag option,
.select-status option {
  color: #214757;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
} */
.project-table-container {
  border: 1px solid #dfe8eb;
  border-radius: 10px;
  margin-bottom: 21px;
}

.project-table {
  width: 100%;
}

.project-table thead {
  border-radius: 10px;
  background: #f7fafc;
  border-radius: 10px;
  border-bottom-left-radius: 10px;
}

.project-table th {
  color: #65748b;
  font-size: 12px;
  font-weight: 600;
  padding: 23px;
  line-height: 13px;
  letter-spacing: -0.2px;
}

.project-table thead tr:last-child {
  border-bottom: 1px solid #dfe8eb;
}

.project-table tbody {
  background: #fff;
  border-radius: 10px;
}

.project-table tbody tr {
  border-bottom: 1px solid #dfe8eb;
  color: #475569;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
}

.project-table tbody tr:last-child {
  border-bottom: none;
}

.action-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action-icon span {
  cursor: pointer;
}

.project-bar {
  display: flex;
  justify-content: right;
  align-items: center;
}

.project-bar p {
  color: #404040;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  margin: 7px;
}

.left-bar {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #dfe7eb;
  border-right: none;
  background: #fff;
  padding: 7px;
}

.right-bar {
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #dfe7eb;
  border-left: none;
  background: rgba(223, 231, 235, 0.2);
  padding: 7px;
}

.project-header-title {
  display: flex;
  align-items: center;
}

.create-header-link,
.create-header-link:hover {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}

.project-header h3 {
  color: #333;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  margin: 0px;
}

.link-arrow {
  color: #000000;
  width: 25px;
}

.project_complete {
  color: #009C22;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
}

.project_hold {
  color: #619FFC;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
}

.project_progress {
  color: #F48F23;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
}

.project_pending {
  color: #F8B146;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
}

.project_cancelled {
  color: #EF4733;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: capitalize;
}


.name-input {
  margin-bottom: 30px;
}

.name-input p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.name-input Input {
  border-radius: 5px;
  border: 0.75px solid #d9d9d9;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px;
}

.create-project-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.select-client-name.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 585px;
  height: 51px;
  font-weight: 500;
  padding: 8px;
}

.client-name p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.project-value {
  width: 45%;
}

.project-value p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.project-value Input {
  border-radius: 5px;
  border: 0.75px solid #d9d9d9;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px;
}

.date-fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.date,
.days-value {
  width: 45%;
}

.days-value Input {
  border-radius: 5px;
  border: 0.75px solid #d9d9d9;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 16px;
}

.date-fields p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.create-project-select-tag {
  margin-bottom: 30px;
}

.create-project-select-tag p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.create-project-select {
  width: 100%;
}

.ant-select {
  color: #333;
}

.create-project-description {
  margin-bottom: 30px;
}

.create-project-description p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.create-project-description Textarea {
  border-radius: 5px;
  border: 0.75px solid #d9d9d9;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 20px;
}

.name-input Input::placeholder,
.project-value Input::placeholder,
.create-project-description Textarea::placeholder {
  color: #cacaca;
}

.mandatory-field {
  color: rgb(244, 32, 32);
}
/* new tabs content for create employee page
 */

.employeeTabProfile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 51px;
  row-gap: 35px;
  padding: 40px 0px;
}

.profileTabEmergencyTitle {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 35px;

}

.profileTabEmergencyContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 51px;

}

.employeePageTabContent .input-number-item {
  padding: 12px;
  top: 2px;
}

.employeePageTabContent .ant-select-arrow {
  top: 53% !important;
}

.employeeTabEmployement {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 51px;
  row-gap: 37px;
  padding: 40px 0px;
}

.employeePageTabAlign input{
 
  font-size: 12px;
  font-weight: 500;

}

.ant-input-lg {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 13px;
}

.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 0px !important;

}

.employeePageTabAlign .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #152E38 !important;
}

.employeePageTabAlign .ant-tabs-tab {
  padding: 12px 0px !important
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 600;
}

.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding-right: 28px;
}


.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  padding: 11px 28px 11px 0px;
}

.ant-tabs-tab-btn {
  color: #A8A8A8;
  font-size: 14px;
  font-weight: 500;
}

.tabTilte {
  color: #A8A8A8;
  font-size: 14px;
  font-weight: 500;

}

.documentTabAddButton {
  padding: 20px 0px;
}

/* change */


.headerWithSave {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.breadcrumbContent {
  display: flex;
  padding: 12px 0px;
}

.breadcrumbContent img {
  padding: 0px 14px;
  height: 14px;
  margin-top: 5px;
}

.emplyeesBreadcrumb {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: #C2C2C2;

}

.emplyeeBreadcrumb {
  color: #838383;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  text-align: left;

}

.employeeSavebtn {
  padding: 13px 33px;
  border-radius: 8px;
  background-color: #234756;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}


.experiencedField {

  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px !important;
}

.experiencedField .ant-input {
  border: none;
 font-size: 12px ;

}

.ex_yearField {
  border-left: 1px solid #F0F0F0;
  width: 50%;
}
  .ex_monthField{

 width: 50%;

} 

.experience input {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.experience input:checked ~ .checkmark {
  background-color: #002B4E !important;
}

.experience input[type = 'checkbox'] {
  accent-color: #214757;
}

.ant-picker-input > input {

  font-size: 12px;
  font-weight: 500;
   
}

.ex_Text{

   text-align:start;
   padding: 15px 0px 15px 5px ;
  text-align:start;
  font-size: 12px;
  font-weight: 500;
  width: 400%;
}
.experiencedField .ant-input{
  
   padding: 17px 0px 17px 20px !important;

 
   
}

.ex_data{
 color: #333333;
}
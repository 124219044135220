.cardBody {
    display: grid;
    overflow: auto;
    height: calc(100vh - 190px);
   grid-template-columns:  repeat( auto-fit, minmax(350px, 1fr) );
    /* grid-template-columns: repeat(auto-fill, 283px); */
    /* grid-template-rows: 1fr 1fr 1fr 1fr; */
    /* grid-template-rows: repeat(auto-fill, 400px); */

    /* grid-template-rows: 200px repeat(auto-fill, 100px) 300px; */
    column-gap: 2%;
    row-gap: 3%;
}
.resourceStyle {
  padding: 0px;
}
.card {
    border:1px solid #EAEAEA;
    border-radius: 10px;
    max-height: 450px !important;
}
.project_header {
    border-radius: 10px 10px 0px 0px;
    padding:15px !important;
    background-color: rgb(248, 248, 249);
    display: flex;
    justify-content: space-between;
}
.editResource {
    transform: rotate(90deg);
}
.threeSides {
    border-bottom: 1px solid #EAEAEA;
    
    border-right: 1px solid #EAEAEA;
}
.cardBody::-webkit-scrollbar {
    width: 5px;
    display: none !important;
  }
  
  /* Track */
  .cardBody::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .cardBody::-webkit-scrollbar-thumb {
    background: #888; 
     border-radius:10px;
  }
  
  /* Handle on hover */
  .cardBody::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .employeeList {
    overflow: auto;
    min-height: 350px;
  }

  .employeeList::-webkit-scrollbar {
    width: 5px;
    /* display: none !important; */
  } 

  .employeeList::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .employeeList::-webkit-scrollbar-thumb {
    background: #888; 
     border-radius:10px;
  }
  
  /* Handle on hover */
  .employeeList::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .unAssignedEmployees , .trainee{
   
  }

  .flex-du {
    border-left: 1px solid #EAEAEA;
    display: flex;
    flex-direction: column;
    width: 0%;
    display: none;
  }

  .flex-du-after {
    border-left: 1px solid #EAEAEA;
    display: flex;
    flex-direction: column;
    width: 20%;

  }

  .burger-icon{
    height: 25px;
    width: 30px;

  }

  .un_assigned {
    max-height: 400px;
    min-height: 400px;
    /* max-height: calc(100vh - 60%); */
    overflow: auto;
  }

  .un_assigned::-webkit-scrollbar {
    width: 5px;
    /* display: none !important; */
  } 

  .un_assigned::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .un_assigned::-webkit-scrollbar-thumb {
    background: #888; 
     border-radius:10px;
  }
  
  /* Handle on hover */
  .un_assigned::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .trainee {
    max-height: 354px;
    min-height: 354px;
    /* max-height: calc(100vh - 60%); */
    overflow: auto;
  }

  .trainee::-webkit-scrollbar {
    width: 5px;
    /* display: none !important; */
  } 

  .trainee::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .trainee::-webkit-scrollbar-thumb {
    background: #888; 
     border-radius:10px;
  }
  
  /* Handle on hover */
  .trainee::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  /*Drawer*/
  .drawer_boody {
    padding:40px 24px;
  }
  /*DragandDrop*/
  .invisible{
    display: none;
    transition: 1sec ease-in;
  }
  .item{
    background: #fff;
    /* margin: 20px; */
    padding: 20px;
    border-radius: 3px;
    cursor: pointer;
  }
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// html {
//   font-size: 62.5%;
// }

body {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;

  // * {
  //   @media only screen and (max-width: 600px) {
  //     font-size: 10px !important;
  //   }
  // }

  // .ant-spin-lg .ant-spin-dot {
  //   @media only screen and (max-width: 600px) {
  //     font-size: 32px !important;
  //   }
  // }
}



.sm-input {
  &--u {
    input {
      text-transform: uppercase;
    }
  }
}
.css-nakgy8-TimeKeeper, .css-1nrlsdz, .react-timekeeper {
  position: absolute !important;
  z-index: 999;
  display: block !important;
}
.sidePopup {
    position: fixed;
    top: 0;
    right: -540px; /* Initially hidden to the right */
    width: 540px;
    height: 100%;
    background-color: #FFFFFF;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    z-index: 999;
    border: 1px solid #CED4DA;
    transition: right 0.3s ease-in-out; /* Animation for opening and closing */

  }
  
  .sidePopup.open {
    right: 0px; /* Displayed when open */
    /* margin-left: 10px; */
  }

  .top-head{

    display: flex !important;
    justify-content: space-between !important;
    height: 67px;
    background-color:#FAFAFA;
    padding: 18px;

  }
.Details-Page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  font-family: Montserrat;
  color: #333333;
}
.crums {
  display: flex;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #333333;
}
.crums1 {
  color: #c2c2c2;
}
.crums2 {
  font-weight: 600;
  padding-left: 8px;
}
.crums-vector {
  width: 15px;
  height: 15px;
  padding-left: 8px;
}

.Status-Box {
  display: flex;
  margin-top: 24px;
  align-items: flex-start;
  background-color: #fcfcfc;
  border-radius: 5px;
  padding: 24px 24px;
}
.status-box-main {
  width: 100%;

  display: flex;
}
.ant-progress-circle .ant-progress-text {
  left: 51% !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #333333 !important;
}

.percent-image {
  width: 102px;
  height: 102px;
}
.statusbox-content {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
}
.statusbox-content1 {
  display: flex;
  width: 900px;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
}
.title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.content1-first {
  display: flex;
  border-right: 1px solid rgba(37, 37, 37, 0.2);
  padding-right: 16px;
  padding-left: 16px;
}
.content1-start {
  display: flex;
  border-right: 1px solid rgba(37, 37, 37, 0.2);
  padding-right: 16px;
}
.content1-end {
  display: flex;
  padding-left: 16px;
}
.content1-start p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #777777;
  padding-right: 16px;
}
.content1-first p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #777777;
  padding-right: 16px;
}
.content1-end p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 16px;
}

.in-prog-stat {
  color: #f8b146;
  padding-right: 9px;
  font-weight: 500;
}
.progress-key {
  display: flex;
  font-weight: 500;
}
.pencil-icon {
  width: 14px;
  height: 14px;
}
.statusbox-content2 {
  display: flex;
  font-size: 14px;
  margin-top: 2px;
}
.content-1 {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  margin-top: 18px;
}
.content-1 img {
  width: 18px;
  height: 18px;
  margin-right: 14px;
}
.content-1 p {
  margin: 0;
}
.projectlist-navbar {
  width: 100%;
  margin-top: 24px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333333 !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}
.ant-tabs-tab-btn {
  color: #a8a8a8 !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}
.ant-tabs-tab:nth-of-type(2) {
  margin-left: 64px;
}
.ant-tabs-tab:nth-of-type(3) {
  margin-left: 64px;
}
.ant-tabs-tab:nth-of-type(4) {
  margin-left: 64px;
}
.ant-tabs-tab:nth-of-type(5) {
  margin-left: 64px;
}
.ant-tabs-tab:nth-of-type(6) {
  margin-left: 64px;
}

/* .ant-tabs-nav-list {
  width: 100% !important;
  border-bottom: 1px solid #ececec;
} */
.projectlist-navbar .ant-tabs-nav-wrap {
  border-bottom: 1px solid #ececec !important;
}
.project-cards {
  margin-top: 24px;
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto auto;
  gap: 24px;
}
.projectcards-list {
  border: 1px solid rgba(37, 37, 37, 0.1);
  padding: 26px 26px;
  border-radius: 5px;
}
.projectcards-list p {
  font-weight: 500;
  line-height: 14px;
  color: #8d99a9;
}
.projectcards-list span {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}
.description-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 24px;
  font-weight: 500;
  color: #757575;
  margin-top: 24px;
  margin-bottom: 150px;
}
.description-container ul li {
  padding-top: 12px;
}
.description-container ul {
  padding-left: 24px;
}
.description-container h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.milestone-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}
.searchbar-container {
  display: flex;
  font-size: 13px;
  color: #214757;
  line-height: 13px;
  font-weight: 400;
}
.search-input {
  margin-right: 16px;
  /* margin: 14px 70px; */

  position: relative;
}
.down-arrow {
  position: absolute;
  right: 15px;
  top: 15px;
  margin-left: 10px;
}
.searchicon {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 15px;
  height: 15px;
}
.search-input input {
  padding: 12px 30px;
  border: 1px solid rgba(205, 205, 205, 0.4);
  border-radius: 4px;
  width: 350px;
}

.search-inin::placeholder{
padding-left: 20px;
text-align: left; /* Align the text to the left */


}
.search-input input:focus {
  outline: none;
}
.search-input select {
  padding: 13px 60px 13px 13px;
  border: 1px solid rgba(205, 205, 205, 0.4);
  border-radius: 4px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.search-input select:focus {
  outline: none;
}
.month-dropdown option {
  background-color: transparent;
  border: 1px solid rgba(205, 205, 205, 0.1);
  outline: none !important;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 13px;
  color: #214757;
}
.Addbutton-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.Add-Milestone {
  padding: 13px 25px;
  border: none;
  background-color: #214757;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  position: relative;
}
.plus-icon {
  width: 15px;
  height: 15px;
  /* position: absolute;
  left: 10%;
  top: 32%; */
  padding-bottom: 3px;
}
.table-container {
  margin-top: 20px;
  border-spacing: 0;
  border-collapse: separate !important;
  border: 1px solid rgba(223, 231, 235, 1);
  border-radius: 8px !important;
  width: 100%;
  overflow-x: auto;
}
.table-container th,
.table-container td {
  padding: 23px 23px;
  text-align: left;
}
.table-datarow td {
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  border-bottom: 1px solid #ddd !important;
}
.table-container thead {
  background-color: #fafafa;
}
.table-container th {
  border-bottom: none;
  font-weight: 600;
  letter-spacing: -0.2px;
}
.table-datastitle {
  font-weight: 600;
  line-height: 14px;
  font-weight: 500;
}
.mid-tablehead {
  width: 18%;
}

.small-tablehead {
  width: 13%;
}
.wide-tablehead {
  width: 25%;
}
.option-tablehead {
  width: 5%;
}
.table-datarow {
  border-bottom: 1px solid #dfe7eb;
}
.data-startend {
  font-weight: 500;
  color: #475569;
  line-height: 12px;
}
.datasatus-color {
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
}
.tablehead-row {
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  color: #65748b;
}
.ant-progress-bg {
  background-color: #214757;
}
.optionbutton {
  background-color: transparent;
  border: none;
}
.pagination-containermain {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.pagination-container {
  margin-top: 31px;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}
.perPage-bar {
  padding: 3px 10px;
  border: 1px solid #dfe7eb;
  margin-right: 17px;
  border-radius: 4px;
  display: flex;
}
.itemperpage-card {
  padding-right: 20px;
  border-right: 1px solid #dfe7eb;
  padding-top: 15px;
  padding-left: 10px;
}

.perPage-bar select:focus {
  border: none;
  outline: none;
}
.perPage-bar select option[disabled] {
  display: none;
}
.pagination-container p {
  margin-right: 17px;
  margin-top: 12px;
}
.pagination-container1 {
  display: flex;
  align-items: center;
}
.arrowbutton-pagination {
  padding: 13px 16px;
  background-color: transparent;
  border: 1px solid #dfe7eb;
  border-radius: 4px;
}
.pagination-container1 span {
  margin-right: 17px;
}
.leftvector-icon {
  color: #404040;
  width: 22px;
  height: 22px;
}
.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}
.css-tj5bde-Svg {
  color: #214757;
  width: 15px;
}
.search-input input::placeholder {
  color: #214757;
  font-weight: 400;
}

.css-1bnj24w-IndicatorsContainer {
  position: relative;
}

.css-tlfecz-indicatorContainer {
  padding: 0 !important;
}
.css-1okebmr-indicatorSeparator {
  margin: 0 !important;
  width: 0 !important;
}
.Main-Container-Others {
  display: flex;
  gap: 16px;
  margin-bottom: 100px;
  flex-direction: column;
  margin-top: 24px;
}
.toptwo-containerslist {
  display: flex;
  gap: 16px;
  /* overflow-x: auto; */
  margin-top: 8px;
}

.Sub-Container-Others {
  border: 1px solid rgba(223, 231, 235, 1);
  border-radius: 8px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 10px);
}
.Sub-ContainerNav-Bar-container {
  width: 100%;
  padding: 25px 24px;
}
.Sub-TableList {
  border-spacing: 0;
  border: 1px solid rgba(223, 231, 235, 1);
  overflow-x: auto;
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
.Sub-ContainerNav-Bar {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.Sub-ContainerNav-Bar span {
  font-size: 16px !important;
  font-weight: 600;
  width: 40%;
}
.subContainer-buttons {
  display: flex;
  width: 60%;
  justify-content: flex-end;
}
.subContainer-buttons button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #333333;
}
.end-button {
  margin-left: 21px;
}
.addbuttonPlusicon {
  margin-bottom: 3px;
  margin-right: 6px;
  line-height: 14px;
  fill: #333333 !important;
}
.Sub-TableList th,
.Sub-TableList td {
  padding: 19px 24px;
  text-align: left;
}

.tabledatarow-maintable {
  border-bottom: 1px solid #ddd !important;
  line-height: 12px;
  font-weight: 500;
  color: #475569;
  font-size: 14px;
}
.Sub-TableList thead {
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  color: #65748b;
  background-color: #fafafa;
}
.maincolumn-firstrowcolumn {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}
.Milestone-drawer-container {
  display: flex;
  flex-direction: column;
}
.Milestone-drawer-container header {
  display: flex;
  background-color: #fafafa;
  color: #333333;
  line-height: 20px;
  width: 100%;
  padding: 24px 28px;
  justify-content: space-between;
  align-items: center;
}
.milestone-header-content {
  font-size: 20px !important;
  margin: 0 !important;
}
.MilestoneClose-icon {
  width: 30px;
  height: 30px;
  background-color: rgba(21, 46, 56, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MilestoneClose-img {
  width: 10px;
  height: 10px;
}

.Milestones-main-drawer .ant-drawer-body {
  padding: 0 !important;
}
.table-datarow .ant-progress-line {
  width: 75%;
}
.Milestone-drawer-container form {
  margin: 40px 26px;
}
.milestoneInputs-container {
  display: flex;
  flex-direction: column;
  color: #212529;
}
.milestoneInputs-container label {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.milestoneInputs-container input {
  padding: 16px 15px;
  border: 1px solid rgba(206, 212, 218, 1);
  border-radius: 5px;
}

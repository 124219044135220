.sale_card {
    border-radius: 8px;
    box-shadow: 5px 5px 10px rgba(#000000, 0.1);
    transition: all 0.5s;
    color: #fff;
    font-weight: 550;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 5px 5px 15px rgba(#000000, 0.1);
    }
    .ant-card-head {
        border-bottom: none !important;
        color: #fff;
        font-size:1.2rem;
        font-weight: bold;
    }
    p {
        letter-spacing: .1rem;
    }
    span {
        padding-left: 1rem;
        font-weight: bold;
    }
    &__1 {
        background: linear-gradient(45deg,#321fdb,#1f1498)!important;
        border-color: #1f1498!important;
    }
    &__2 {
        background: linear-gradient(45deg,#39f,#2982cc)!important;
        border-color: #2982cc!important;
    }
    &__3 {
        background: linear-gradient(45deg,#f9b115,#f6960b)!important;
        border-color: #f6960b!important;
    }
    &__4 {
        background: linear-gradient(45deg,#e55353,#d93737)!important;
        border-color: #d93737!important;
    }
}
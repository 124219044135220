.sm__sidebar {
  background-color: $color-accent;
  height: 100vh !important;

  .ant-layout-sider-children {
    .ant-menu {
      background-color: transparent !important;
      height: 100vh !important;
      // overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0px;
            
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

      .ant-menu-item {
        span {
          color: $color-bg !important;
          a {
            color: $color-bg !important;
          }
        }

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.2) !important;
        }
      }
      .ant-menu-item-selected {
        background-color: $color-primary;
        span {
          color: $color-accent !important;
          a {
            color: $color-accent !important;
          }
        }

        &:hover {
          background-color: $color-primary !important;
          span {
            color: $color-accent !important;
            a {
              color: $color-accent !important;
            }
          }
        }
      }
    }
  }

  .ant-layout-sider-trigger {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;

    span {
      color: $color-accent;
    }
  }
}

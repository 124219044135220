@import "./abstracts/variables";
@import "./abstracts/mixins";

@import "./base/base";
@import "./base/typography";
@import "./base/utils";

@import "./components/button";
@import "./components/card";
@import "./components/detail";
@import "./components/form";
@import "./components/input";
@import "./components/modal";
@import "./components/popover";
@import "./components/table";
@import "./components/upload";

@import "./layout/grid";
@import "./layout/header";
@import "./layout/sidebar";

@import "./pages/home";
@import "./pages/dashboard";
@import "./pages/auth/signup";

//side bar style

.full_side_bar {
  .small_bar {
    display: none !important;
  }
  .full_bar {
    display: block;
  }
  #main-admin-sidebar {
    width: 16%;
  }
  .ad-main-content {
    width: 84%;
    transition: 0.5s all;
  }

  iframe {
    z-index: -10 !important; // iframe block the view when reloading
  }
}
.small_side_bar {
  .full_bar {
    display: none;
  }
  .small_bar {
    display: block;
  }
  #main-admin-sidebar {
    width: 5%;
  }
  .ad-main-content {
    width: 95%;
    transition: 0.5s all;
  }
  .qk_logo2 {
    display: block;
    transition: 0.5s all;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qk_logo {
    display: none;
    transition: 0.5s all;
  }

  iframe {
    z-index: -10 !important; 
  }
}
#main-admin-sidebar,
#main-admin-content {
  transition: 0.5s all;
}

// .newcard_overall {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .newcard {
//   width: 23.5%;
//   box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
//   border-radius: 8px;
//   padding: 26px 16px;

//   .card_header {
//     font-family: "Montserrat";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 14px;
//     text-transform: uppercase;
//     color: #8d99a9;
//   }
// }
.sm__detail {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  display: flex;
  align-items: center;

  &--label {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
}

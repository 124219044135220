@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Ubuntu&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100%;
  /* height: 100%; */
  /* font-family: "Inter", sans-serif; */
  font-family: "Montserrat";
  font-style: normal;
}

html {
  height: 100%;
  /* overflow: auto; */
}

.div-wrapper {
  /* height: 100vh; */
  padding: 30px;
}

/* p {
  margin: 0 !important;
} */

.pl_4 {
  padding-left: 4rem !important;
}

.c_dark {
  color: #333333 !important;
}

.c_light {
  color: #757575 !important;
}

.c_30 {
  color: #303030;
}

.c_21 {
  color: #212121;
}
.pointer {
  cursor: pointer !important;
}
.mb-05 {
  margin-bottom: 1rem !important;
}
.mb-10 {
  margin-bottom: 1.25rem !important;
}
.mb_15 {
  margin-bottom: 0.938rem !important;
}

.mb-20 {
  margin-bottom: 2rem !important;
}

.mb-23px {
  margin-bottom: 23px !important;
}
.mb-30 {
  margin-bottom: 3rem !important;
}

.mb-24 {
  margin-bottom: 1.5rem !important;
}

.mb-5e {
  margin-bottom: 0.5em;
}

.mb-3 {
  margin-bottom: 0.3rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-20 {
  margin-top: 2rem;
}
.mt-20px {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 1rem;
}
.mt-70 {
  margin-top: 7rem;
}

.mr-16 {
  margin-right: 1rem !important;
}

.mr-30 {
  margin-right: 3rem;
}

.mr-40 {
  margin-right: 4rem;
}

.mr-20 {
  margin-right: 2rem !important;
}

.mr-10 {
  margin-right: 2rem !important;
}

.mr-5 {
  margin-right: 0.5rem !important;
}

.mr_30 {
  margin-right: 30px !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.ml-13 {
  margin-left: 0.813rem !important;
}

.ml_30 {
  margin-left: 30px !important;
}

.projectStatus-select:not(.ant-select-disabled:hover) .ant-select-selector {
  border: 1px solid #ced4da !important;
}
.projectStatus.ant-select:not(.ant-select-disabled:hover) .ant-select-selector {
  height: 48px !important;
}
/*<---------------------------------------------------------->*/
.projectTableBody tr td {
  color: #475569 !important;
}

.project_input .ant-input {
  border: 1px solid #f0f0f0 !important;
}

.mt_10 {
  margin-top: 10px !important;
}

.mt_30 {
  margin-top: 30px !important;
}
.m_0 {
  margin: 0px !important;
}
.mb_5 {
  margin-bottom: 5px !important;
}
.mb_10 {
  margin-bottom: 10px !important;
}
.mb_18 {
  margin-bottom: 18px !important;
}
.mb_20 {
  margin-bottom: 1.25rem !important;
}
.mb_24 {
  margin-bottom: 1.5rem !important;
}
.mb_30 {
  margin-bottom: 1.875rem !important  ;
}
.mb_32 {
  margin-bottom: 2rem !important;
}

.ml-16 {
  margin-left: 1rem !important;
}

.w_5 {
  width: 5% !important;
}
.w_8 {
  width: 8% !important;
}
.w_12 {
  width: 12% !important;
}

.w_15 {
  width: 15% !important;
}

.w_20 {
  width: 20% !important;
}

.w_25 {
  width: 25% !important;
}

.w_30 {
  width: 30% !important;
}
.w_54 {
  width: 54% !important;
}

.w_70 {
  width: 70% !important;
}

.w_80 {
  width: 80% !important;
}

.w_100 {
  width: 100% !important;
}

.fs_12 {
  font-size: 12px !important;
}

.fs_13 {
  font-size: 13px !important;
}

.fs_14 {
  font-size: 14px !important;
}

.fs_15 {
  font-size: 15px !important;
}

.fs_16 {
  font-size: 16px !important;
}

.fs_18 {
  font-size: 18px !important;
}

.fs_20 {
  font-size: 20px !important;
}

.fw_400 {
  font-weight: 400 !important;
}

.fw_500 {
  font-weight: 500 !important;
}

.fw_600 {
  font-weight: 600 !important;
}

.lh_28px {
  line-height: 28px;
}

.d-flex {
  display: flex !important;
}

.d-flex_al_c {
  display: flex !important;
  align-items: center;
}

.d-flex_al_c_jc_e {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.d-flex_jc_c_al_c {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.d-flex_jc_sb_al_c {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.d-flex_jc_sb_al_e {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}
.p_0 {
  padding: 0px !important;
}
.w_329px {
  width: 329px !important;
}

.p_14 {
  padding: 14px !important;
}
.p_15 {
  padding: 15px !important;
}

.light .anchor {
  color: #475569 !important;
}
.project_date_placeholder {
  font-weight: 500 !important;
  font-style: 16px !important;
}

.project_textArea {
  padding: 9px !important;
}
.blood.ant-select:not(.ant-select-disabled:hover) .ant-select-selector {
  border: 1px solid #f0f0f0 !important;
}
.commonDatepicker.ant-picker {
  border: 1px solid #f0f0f0 !important;
}
.project_textArea.ant-input {
  border: 1px solid #f0f0f0 !important;
}
.overlayLayout {
  transition: 0.3s ease-in;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000080;
  z-index: 1;
}
.overlay_container {
  background-color: #00751a;
  position: fixed;
  top: 0;
  width: 500px;
  height: 100vh;
  display: flex;
  justify-content: end;
  z-index: 100000;
}
.ant-drawer-header {
  padding: 0px 0px !important;
}
.tp {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: #20f74f3d;
}
.blood.anticon svg {
  font-size: 10px !important;
}
.cancel_btn {
  border: none;
  background-color: transparent;
}
.confirm_btn {
  border: none;
  background-color: #204656;
}
/*<---------------------------------------------------------->*/

.fl-end {
  display: flex;
  justify-content: flex-end;
}
.font-weight-content {
  font-weight: 600;
}
.card-content {
  height: 150px;
  margin-top: 30px;
  border-radius: 6px;
}

.card-content span {
  font-size: 33px;
  font-weight: 600;
}

.login-wrap {
  height: 100vh;
  background-color: #d3d3d31c;
}

.username-in {
  margin-top: 12px;
}

.login-flex-wrap {
  height: 100vh;
  /* background-color: #d3d3d34f; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-flex {
  background: white;
  border-radius: 20px;
  box-shadow: 7px 7px 13px lightgrey;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

  width: 750px;
  max-width: 750px;

  display: flex;
  height: 300px;
}

.gx-app-logo-content {
  border-right: 1px solid;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #2e2a2b; */
  background: #214757;

  /* width: 60%; */
  width: 45%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.sign-input-menu-field.ant-input-affix-wrapper {
  border-radius: 5px !important;
  padding: 7px !important;
}

.button-signup:disabled {
  background-color: #038fdd;
  color: white;
}

.button-signup:hover {
  background-color: #038fdd;
  color: white;
}

.button-signup:hover:disabled {
  background-color: #038fdd;
  color: white;
}

.button-signup:active {
  background-color: #038fdd;
  color: white;
}

.button-signup:active:disabled {
  background-color: #038fdd;
  color: white;
}

.button-signup:focus {
  background-color: #038fdd;
  color: white;
}

.button-signup:focus:disabled {
  background-color: #038fdd;
  color: white;
}

.app-login-content {
  padding: 10px 20px;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gx-app-logo-wid img {
  width: 100%;
}

.login-header {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #212121;
}

.signin-title {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #212121;
}

.ant-form {
  margin-top: 10px;
  width: 100% !important;
}

.ant-form-item-control-input-content {
  /* display: block !important; */
}

.ant-form-item-control-input {
  /* position: relative !important; */
  /* display: block !important; */
}

.ant-btn-primary {
  float: left !important;
}

/* Employess */
.input-group {
  position: relative;
  width: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opt_expense {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.dropdown-employee {
  height: 48px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  /* padding: 10px; */
  text-decoration: none;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  margin-right: 10px;
  width: 100%;
}

.input-group-employee {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-group-employee::placeholder {
  color: #d9d9d9c7;
}

.input-group::placeholder {
  color: #d9d9d9c7;
}

.dropdown-category {
  color: #8d8a8ac7;
}

.dropdown-list {
  height: 31px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  text-decoration: none;
  outline: none;
  border: 1px solid #d9d9d9;
  /* color: #000000e3; */
}

.dropdown-list option {
  color: #000000e3;
}

.flex-col span {
  margin: 0 !important;
}

.dropdown-list-date {
  border-radius: 7px;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  width: 130px;
}

.input-relative-div {
  position: relative;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #ffff;
}

.input-div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* padding: 10px 0 25px 0; */
  margin: 25px 0;
}

.input-group1 {
  width: 100%;
  border-radius: 7px;
  height: 36px;
}

.top-nav {
  font-size: 17px;
  margin-bottom: 0;
}

.mt-25 {
  padding-top: 25px;
  border-top: 1px solid #bfbfbf59;
}

.divider-employee {
  margin-top: 0 !important;
}

.div-wrapper-dashboard {
  padding: 10px 30px;
  /* height: 100%; */
  /* background-color: #d3d3d34f; */
  /* background-color: rgb(211 211 211); */
}

.search-span {
  position: absolute;
  right: 7px;
  top: 47%;

  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #757575;
}

.search-span-projects {
  position: absolute;
  right: 0;
  top: 55%;
  transform: translate(-50%, -50%);
}

/* .anticon svg {
  font-size: 18px;
  color: #757575;
} */
.anticon svg {
  font-size: 13px;
}

.input-group-projects {
  /* position: relative; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -ms-flex-align: center;
  width: 80%;
}

.input-group-projects::placeholder {
  color: #d9d9d9c7;
}

.margin-wrapper {
  margin-right: 10px;
}

.bread {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-list button {
  /* background-color: transparent;
  height: 36px;
  width: 136px;
  color: white;
  border: 1px solid #038fdd;
  outline: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  margin-left: 10px;
  background-color: #038fdd; */
  padding: 9px 26px;
  /* width: 200px; */

  background: #214757;
  border-radius: 3px;
  outline: none;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 10px;
}

.probtn-back {
  width: 100px !important;
  background: #214757;
  border-radius: 3px;
  outline: none;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 10px;
}

.probtn-employee {
  padding: 14px !important;

  background: #214757;
  border-radius: 3px;
  outline: none;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 10px;
}

.button-div {
  background-color: #038fdd;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-div-active {
  background-color: #429441;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-div-hold {
  background-color: #fbbc05;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-div-discontinue {
  background-color: #4bc2cc;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-div-cancel {
  background-color: #f14c52;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-div-completed {
  background-color: #32a13f;
  height: 36px;
  width: 100px;
  border-radius: 7px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.ant-menu-title-content {
  color: #fff !important;
}

/* .logo{
  
  background-color: #000000;
  overflow: auto;
} */
.logo img {
  position: fixed;
  top: -24px;
  z-index: 1000;
  padding: 14px 23px;
  background-color: #2e2a2b;
}

/* Spinning */
.spinning-star {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.spinning-star1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

/* Add Employee,Add Clients */
.employee-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 35px;
}

.title-wrapper {
  margin-bottom: 10px;
  color: #444242;
  font-size: 14px;
  font-weight: 600;
}

.title-nav {
  border-bottom: 1px solid #bfbfbf59;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blood-list {
  height: 33px !important;
  width: 270px;
}

.employee-wrapper-secondary {
  padding-top: 35px;
  display: flex;
  justify-content: space-between;
}

.dropdown-date {
  height: 33px;
  min-width: 246px;
}

.hide-content {
  display: none;
}

.tab-title-wrap {
  display: flex;
  flex-direction: column;
}

.table-pad {
  padding-top: 20px;
}

.experience-render {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.check-wrapper {
  padding-top: 30px;
}

.title-nav-secondary {
  padding-top: 15px;
  border-bottom: 1px solid #bfbfbfd1;
  min-height: 65px;
}

.tab-render {
  padding-top: 30px;
}

.add-button {
  width: 14%;
  height: 35px;
  float: right;
  background-color: #038fdd;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 7px;
  border: none;
  margin-bottom: 5px;
  min-width: fit-content;
  padding: 5px 10px 5px 10px;
  outline: none;
}

.experience-render-role {
  display: flex;
  align-items: center;
  padding-top: 15px;
  justify-content: space-between;
}

.experience-category {
  padding-top: 5px;
}

.add-salaries {
  padding: 14px 26px;

  background: #214757;
  border-radius: 3px;
  outline: none;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  float: right;
}

/* .ant-picker {
  border-radius: 6px !important;
  width: 300px;
  height: 47px;
} */
.total-days.ant-input {
  border-radius: 6px !important;
  width: 300px;
  height: 47px;
}

.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
  /* height: 44px; */
}

.ant-select {
  color: rgb(83, 83, 83);
  line-height: none;
  /* width: 100%; */
}

.upcome-view
  .dashboard-dropdown-graph
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  line-height: 33px;
}

/* Add Salaries  Pop Up*/
.modal_form_fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_amount {
  width: 46%;
}

.ant-modal-content {
  width: 75%;
  margin: auto;
}

.role-list {
  width: 240px !important;
}

.salary-picker {
  width: 160px;
}

.login-input {
  width: 100% !important;
  /* background-color: #e8f0fe; */
}

.ant-input-prefix {
  margin-right: 8px !important;
}

.salary-input {
  width: 160px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

/* Employee Details */
.back-button {
  width: 14%;
  padding: 5px 10px 5px 10px;
  height: 35px;
  float: right;
  background-color: #072b40d1;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  border-radius: 7px;
  border: none;
  margin-bottom: 5px;
  border: 1px solid #072b40d1;
  transition: 0.5s;
}

.edit-button {
  width: 7%;
  height: 35px;
  float: right;
  background-color: #1599e6d1;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  border-radius: 7px;
  border: none;
  margin-bottom: 5px;
  border: 1px solid #1599e6d1;
  transition: 0.5s;
  margin-left: auto;
  margin-right: 20px;
}

/* .back-button:hover {
  box-shadow: 1px 1px 1px #aaaaaa;
} */

.flex-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-content {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 40px; */
}

.details-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 40px; */
}
.pay-content {
  width: 115%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-content-col {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-content-project {
  width: 75%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.phone-wd {
  width: 80% !important;
}

.ant-modal-footer {
  display: flex;
  padding-left: 198px;
}

.name-wrapper {
  margin: 0;
  /* width: 48%; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-flex-align: center;
}
.name-wrappers {
  margin: 0;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-flex-align: center;
}
.pay-data-field {
  box-sizing: border-box;
}
.pay-wrappers {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-flex-align: center;
}

.payslip-wrappers {
  margin: 0;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-flex-align: center;
}
.name-secondary {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
}

.data-content {
  width: 65%;
  min-height: 20px;
  color: #6c6969;
  font-weight: 500;
}

.data-content2 {
  width: 55%;
  min-height: 20px;
  color: #6c6969;
  font-weight: 500;
  margin-right: 95px;
}
.des-content {
  width: 38%;
  line-height: 2;
  color: #6c6969;
  font-weight: 500;
}

/* client css */
.field-holder {
  margin: 10px 0;
}

.cl-input {
  width: 230px;
}

.flex-sp {
  display: flex !important;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.width-50 {
  width: 50%;
}

.font-500 {
  font-weight: 500;

  margin: 0;
}

.font-600 {
  font-weight: 600;
  color: #333333;
}

.sm__grid--col-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(20rem, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.sm__grid--col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

/* permission */
.permission {
  padding: 40px;
}

.per {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field {
  margin-bottom: 10px;
  margin-top: 10px;
}

/* CONTENT */
/* .header { */
/* width:1328px; */
/* padding: 10px;
  background-color: #f7fafd; */
/* } */

.ant-layout-header {
  background-color: #ffffff;
}

/* .head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */

.dropbtn {
  cursor: pointer;
  font-weight: bold;
  color: rgb(90, 85, 85);
  margin-top: 10px;
  margin-right: 20px;
}

/* .dropdown {
  position: relative;
  display: inline-block;
  padding: 0 100px 0 0;
  margin-bottom: -5px;
} */

.dropdown-content {
  display: none;
  position: absolute;
  right: -35px;
  background-color: white;
  width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: -25px;
}

.dropdown-menu {
  /* text-align: center; */
  width: 103px;
}

.ant-menu-vertical .ant-menu-submenu-title {
  width: 20% !important;
  margin: auto !important;
}

.dropdown-content a {
  color: rgb(58, 55, 55);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-weight: 500;
}

.plus {
  color: rgb(100, 94, 94);
  font-size: 18px;
  margin-right: 10px;
}

.pluss {
  color: rgb(100, 94, 94);
  font-size: 18px;
  margin-right: 15px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown-menu:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropbtn:hover.dropdown-content {
  display: block;
} */

.platform {
  padding: 10px 30px;
  /* height: 100%; */
  background-color: #d3d3d34f;
}

.btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.sideicon {
  font-size: 18px;
  margin-right: 15px;
}

/* project */
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.ant-input-group.ant-input-group-compact > *:last-child {
  border-right-width: 1px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.probtn {
  padding: 10px 26px;
  background: #214757;
  border-radius: 7px;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}

.probtn2 {
  padding: 9px 16px;
  background: #214757;
  border-radius: 7px;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}

.probtn-clear {
  padding: 12px 24px;

  color: #214757;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #214757;
}

.probtn-clear:hover {
  padding: 14px 24px;
  background: #214757;
  border-radius: 3px;
  outline: none;
  border: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.onboard_btn {
  border-radius: 7px;
  background-color: transparent;
  height: 36px;
  width: 80px;
  color: white;
  border: 1px solid #038fdd;
  outline: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin-left: 10px; */
  background-color: #038fdd;
}

.active_color {
  background-color: #038fdd;
}

.onboard_date {
  width: 100%;
}

.acceptbtn {
  color: white;
  background-color: #44bd32;
}

.acceptbtn:hover {
  background-color: #44bd32;
  color: white;
}

.btnClick {
  border-radius: 5px;
  padding: 15px;
}

.btnClick:hover {
  background-color: #038fdd;
  color: white;
}

.nameCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.name-col {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.col {
  width: 30%;
}

.coldate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width:100%; */
}

.date {
  /* width: 50%; */
  width: 100%;
}

.blood.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 48px !important;

  font-weight: 500 !important;
  padding: 7px 11px !important;
  border-radius: 7px !important;
}

.project_placeholder .ant-select-selection-placeholder {
  color: #cacaca !important;
}

.interview-select.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 7px !important;
}

.dropdown-admin.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 48px !important;

  font-weight: 600 !important;
  padding: 7px 11px !important;
  border-radius: 0px !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  cursor: auto;
  padding-top: 10px !important;
}

.editicon {
  color: rgb(24 144 255);
}

.bbread {
  width: 50%;
  /* padding-left: 50px; */
  margin-left: 50px;
}

.dropdown {
  /* width: 50%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-form-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin_input {
  width: 100%;
  box-sizing: border-box;
}

.margin {
  margin-bottom: 10px;
}

.form_in {
  width: 140%;
  border-radius: 6px;
}

.drpp {
  height: 36px;
  width: 100%;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  text-decoration: none;
  outline: none;
  border: 1px solid #d9d9d9;
}

.hikeshow {
  /* display: block; */
}

.hidehike {
  display: none;
}

.exshow {
  display: block;
}

.exhike {
  display: none;
}

.job_container {
  width: 100%;
}

.jobs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  -ms-flex-align: center;
}

.careers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 34%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.request {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 34%;
  margin-bottom: 3rem;
}

.onsearch {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.job_li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.exp_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exp_min {
  margin-right: 10px;
}

.status {
  display: flex;
  justify-content: space-between;
}

/* Jobs */
.job_per {
  width: 40%;
}

.job_pers {
  width: 30%;
}

.jobscontainer {
  padding: 20px;
}

/* .container {
  padding: 20px;
  overflow-x: hidden;
} */

.grp_btn {
  display: flex;
  justify-content: space-between;
}

.expensescontainer {
  padding: 40px;
}

.overflow-overlay {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  /* background: rgba(49, 49, 49, 0.1); */
}

.editwrapper {
  padding: 20px;
}

.expenses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.probtns {
  border-radius: 7px;
  background-color: transparent;
  height: 36px;
  width: 136px;
  color: white;
  border: 1px solid #038fdd;
  outline: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  background-color: #038fdd;
}

/* leave */
.anticon svg {
  font-size: 17px;
}

/* Status */
.status-accept {
  color: green;
  text-transform: capitalize;
}

.status-request {
  color: #f39c12;
  text-transform: capitalize;
}

.status-cancel {
  color: #30336b;
  text-transform: capitalize;
}

.status-reject {
  color: red;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.status-recall {
  color: #3867d6;
  text-transform: capitalize;
}

.reject-button {
  margin-right: 10px;
  border-radius: 5px;
}

.accept-button {
  color: white;
  background-color: #44bd32;
  border-radius: 5px;
}

.from-date {
  width: 300px;
  margin-right: 10px;
  border-radius: 5px;
}

.to-date {
  width: 300px;
  margin-right: 10px;
}

/* salary */
.sal {
  display: flex;
}

.specific {
  padding: 20px;
}

.adex {
  display: flex;
  margin-right: 4px;
}

/* .adrow {
  width: 100%;
} */

.addinp {
  width: 300px;
}

.addinput {
  width: 300px;
  padding: 5px;
  margin-top: 10px;
  outline: none;
  border: 1px solid rgb(224, 219, 219);
  background: transparent;
}

.speci {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_specification {
  width: 300px;
}

.specif {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 20%;
}

.ml11 {
  width: 23.3%;
}

.mr10 {
  font-weight: bold;
  margin-left: 120px;
}

.box {
  display: flex;
  margin-bottom: 10px;
}

/* styles accesories */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.button-section {
  margin: 3%;
}

.button {
  color: white;
  cursor: pointer;
  outline: none;
}

.remove {
  background-color: rgb(192, 53, 53);
}

.remove:hover {
  background-color: rgb(187, 43, 43);
}

.plus-icon.anticon svg {
  font-size: 13px;

  color: #080807;
  margin-left: 5px;
}

.add-icon {
  color: #bfbfbf;
  font-size: 15px;
  margin-right: 6px;
}

.onboard-date {
  height: 47px;
}

.delete-modal {
  font-size: "29px" !important;
  font-weight: "600" !important;
  color: "#333333" !important;
}

.site-tag-plus {
  cursor: pointer;
  padding: 10px 14px;
  display: inline-block;
  border-radius: 6px;
}

.tag-client .anticon svg {
  font-size: 15px !important;
  font-weight: bold !important;
}

.tag-client .ant-tag {
  display: inline-block;
  padding: 10px 14px;
  border-radius: 6px;
}

.ant-tag {
  font-size: 13px;
  /* padding: 5px; */
}

.client-field {
  width: 200px;
  margin-right: 10px;
  padding: 10px 14px;
}

.tagchild .anticon svg {
  font-size: 13px;

  color: #080807;

  margin-left: 6px;
}

.type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-file-label {
  position: relative;
  width: 436px;
  height: 39px;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  text-align: center;
}

.upload-file-label:hover {
  border-color: #1890ff;
}

.upload-file-label span {
  color: #1890ff;
  font-size: 14px;
}

.upload-file-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0;
}

.upload-file-input:hover {
  cursor: pointer;
}

.upload-file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.uploaded-image {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #ff4d4f;
  border-radius: 2px;
}

.uploaded-image img {
  width: 48px;
  height: 48px;
  overflow: hidden;
  object-fit: cover;
}

.uploaded-image i {
  color: #ff4d4f;
  float: right;
  font-size: 16px;
}

.uploaded-image i:hover {
  cursor: pointer;
}

.paymodal .ant-modal-content {
  width: 100%;
}

.admin_input .ant-modal-content {
  width: 85%;
}

.ant-modal-content {
  width: 100%;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-left: 317px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 24px;
}

.para {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.thead-light th {
  background-color: rgba(7, 126, 139, 0.05);
  font-size: 16px;
  color: #22212f;
}

/* .table .thead-light th {
  background-color: rgba(7, 126, 139, 0.05);
  font-size: 13px;
} */

.mixed-chart {
  margin-top: 30px;
  width: 100%;
  /* display: flex; */
  /* justify-content:space-between; */
  position: relative;
}

.opt_expense {
  margin-left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.opt_expenses {
  width: 200px;
  padding: 5px;
  outline: none;
}

.leave_req {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 100px;
  /* background: gray; */
}

.site-card-wrapper {
  background: "blue";
}

.task_list {
  display: flex;
  width: 25%;
  justify-content: space-between;
  font-size: 14px;
  color: rgb(0 0 0 / 85%);
}

.create_button {
  display: flex;
  justify-content: flex-end;
}

.createTask_button {
  height: 36px;
  width: 136px;
  color: white;
  border: 1px solid #038fdd;
  outline: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  margin-left: 10px;
  background-color: #038fdd;
}

.due_date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.gap0 {
  gap: 0px !important;
}

.width100 {
  width: 100%;
}

.wid-50 {
  width: 50%;
}

.wid-40 {
  width: 40%;
}
.wid-10 {
  width: 10%;
}
.wid-5 {
  width: 5%;
}

.task_name_input {
  border: none;
  border-bottom: 1px solid #0000001f;
  width: 100%;
  margin: 1rem 0rem 2rem;
  outline: none;
  height: 3rem;
}

.task_name_input:focus {
  border-bottom: 1px solid #038fdd;
}

.remind_Me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.save_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 7rem;
}

.cancel {
  /* color:rgb(0, 0, 0);
  background-color: #dadada;
  border: 1px solid #cecece;
  height: 36px; border-radius: 7px;
  margin-left: 15px;  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px; */
  cursor: pointer;
}

.save {
  color: white;
  height: 36px;
  width: 115px;
  border: 1px solid #038fdd;
  outline: none;
  padding: 5px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  margin-left: 15px;
  background-color: #038fdd;
}

.date_input {
  border: none;
  border-bottom: 1px solid #0000001f;
  box-shadow: none;
}

/* .date_input:focus{

  border:none;
  border-bottom: 1px solid #038fdd;
  outline:none;
} */
.search_filter_create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* position: absolute; */
  top: 80px;
  left: 0;
}

/* .searches_under_tabs{
  position:absolute;
  top:80px;
} */
.task_searchbox {
  outline: none;
  padding: 5px 10px;
  height: 36px;
  width: 136px;
  border: 1px solid #0000001f;
  border-radius: 7px;
}

.filter_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  outline: none;
  border: 1px solid #0000001f;
  border-radius: 7px;
  background-color: white;
  padding: 5px 10px;
  height: 36px;
  width: 136px;
}

.taskss {
  position: relative;
}

.all_tabs {
  margin-bottom: 3rem;
}

.task_details {
  margin-top: 8rem;
}

.ant-tabs-nav {
  margin-bottom: 5rem;
}

.tasks_lists {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.task_card {
  width: 100%;
  border: none;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.task_card_option {
  position: relative;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.task_options {
  border: none;
  background-color: white;
  box-shadow: 1px 4px 10px 5px #6f6e6e1f;
  list-style-type: none;
  position: absolute;
  padding: 10px;
  top: 18px;
  left: 5px;
}

.bordercolor_pastdue {
  border-left: 3px solid #f14c52;
}

.bordercolor_nodue {
  border-left: 3px solid #429441;
}

.bordercolor_today {
  border-left: 3px solid #4286f4;
}

.bordercolor_tommorrow {
  border-left: 3px solid #d9a50d;
}

.task_card_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #606060;
  font-weight: 400;
  cursor: pointer;
}

.task_number {
  color: #fff;
  padding: 1px 4px;
  border-radius: 4px;
}

.task_day {
  font-weight: 500;
  font-size: 18px;
}

.task_assign_to {
  display: flex;
  align-items: center;
}

.backcolor_pastdue {
  background-color: #f14c52;
}

.backcolor_nodue {
  background-color: #429441;
}

.backcolor_today {
  background-color: #4286f4;
}

.backcolor_tommorrow {
  background-color: #d9a50d;
}

.onboard_role.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 5px 10px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
}

.ant-select:not(.ant-select-disabled:hover) .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
  padding: 9px;
}

.ant-notification-notice-message {
  line-height: 14px;
  font-size: 14px;
}

.ant-notification-notice-icon {
  font-size: 14px;
}

.ant-select-selector {
  padding: 5px 0;
}

.complete {
  position: absolute;
  top: 14px;
  right: 42px;
  color: #038fdd;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.delete_Task {
  cursor: pointer;
  color: #606060;
}

.delete_text {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
}

.confirm_message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.confirm_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_yes_button {
  margin-right: 2rem;
  border: thin solid #c4c4c4;
  border-radius: 8px;
  padding: 6px 16px;
  cursor: pointer;
}

.delete_no_button {
  border: thin solid #038fdd;
  background-color: #038fdd;
  border-radius: 8px;
  padding: 6px 16px;
  color: #fff;
  cursor: pointer;
}

/* .ant-modal-footer{
  padding: 0px !important;
  border-top:none !important;
} */
.ant-modal-content {
  border-radius: 4px !important;
}

.ant-modal-close-x {
  display: none !important;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.percentage {
  display: flex;
  justify-content: space-between;
}

/* .arrow {
  margin-right: 0.5rem;
  font-size: 17px;
} */

.positive {
  color: #27733b;
}

.negative {
  color: #c52227;
}

.onboard_head {
  font-size: 22px;
  font-weight: 500;
  color: #212121;
}

.flexes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onboard_flex {
  display: flex;
  /* justify-content:space-between; */
  align-items: center;
}

.form_inputs {
  width: 31%;
  color: #0f0e0e;
}

.business_detail {
  padding: 8px 16px;
  /* height: 44px; */
  /* border:none;
  outline: none;
  border-bottom: 1px solid rgb(221, 216, 216); */
  /* width:91%; */

  /* padding: 8px 14px;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
   
    border-radius: 0.25rem; */
}

.onboard_input {
  padding: 8px 16px;
}

.onboard_input_name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
  color: #333333;
}

.onboard_table {
  margin-top: 20px;
}

.basic_details {
  margin-top: 20px;
}

.ps {
  overflow-y: scroll !important;
}

.ps::-webkit-scrollbar {
  display: none !important;
}

.qk_sidebar::-webkit-scrollbar {
  display: none !important;
}

.onboard_container {
  padding: 40px;
}

.onboard_divider {
  font-size: 18px;
  font-weight: 500;
  color: #303030;
}

.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  /* color: rgb(33 33 33 / 85%); */
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  color: #303030;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}

.onboard_createbtn {
  border-radius: 3px;
}

.onboard_accept {
  color: #429441;
  text-transform: capitalize;
}

.onboard_reject {
  color: #fbbc05;
  text-transform: capitalize;
}

.onboard_pending {
  color: #30336b;
  text-transform: capitalize;
}

.onboard_failed {
  color: #f14c52;
  text-transform: capitalize;
}

.active-menu {
  background-color: blue;
}

.side-menu {
  background-color: #2e2a2b;
  list-style-type: none;
  margin-top: 70px;
  width: 250px;
  padding: 0 10px 0 10px;
  /* overflow: scroll !important; */
}

.side-menu li {
  list-style: none;
  /* padding:10px; */
  margin: 15px 0 0 -9px;
  padding: 10px;
  width: 247px;
}

.side-menu li:hover {
  background-color: #080807;
}

.side-menu a {
  color: #fff;
  margin-left: 15px;
  padding: 10px;
}

.sidebar-container {
  width: 250px;
}

.layout-containers {
  width: "100vw";
  height: "100vh";
  overflow: hidden;
  display: flex;
}

.dot-icon {
  margin: 0;
  margin-left: 60px;
}

.leave_btn {
  background: #1599e6d1;
  color: white;
  text-decoration: none;
  padding: 5px;
  font-weight: bold;
  border-radius: 3px;
}

.leave_btn:hover {
  background: #1599e6d1;
  color: white;
}

.cursor {
  cursor: default;
  outline: none;
  background: transparent;
  padding: 8px 16px;
}

.cursor.ant-input:focus {
  /* border-color: rgb(224, 219, 219);
  outline: 0;
  box-shadow: none; */
}

.cursor.ant-input:hover {
  /* border-color: rgb(224, 219, 219);
  outline: 0; */
}

.invoice-dashboard {
  width: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin-bottom: 20px;
}

.invoice_dash {
  /* margin: 5px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.invoices_dash {
  /* margin:0; */

  margin: 5px;
  font-size: 15px;
}

.invoicess_dash {
  /* color: gray; */
  font-size: 14px;
}

.invoice_dash_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  background: #f7f7f7;
  border-radius: 6px;
}

.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none;
}

.item_content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 3rem;
}

.expenseTitle {
  margin-bottom: 1rem;
  font-weight: 600;
}

.AddItem Button {
  border-radius: 7px !important;
  background-color: #038fdd !important;
  height: 36px;
  width: 136px;
}

.ant-drawer-content-wrapper {
  width: 500px !important;
}

.ant-drawer-close {
  display: none !important;
}

.drawerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

/* .drawerTitle Button {
  border-radius: 7px !important;
  background-color: #038fdd !important;
  height: 36px;
  width: 100px;
} */

.overallTransaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  background: #f7f7f7;
  border-radius: 7px;
  margin-bottom: 2rem;
}

.transactionDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expenseHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mt_mb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gridDisplay {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 75px;
  row-gap: 20px;
}

.calci {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.calciValue {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px;
  background: #f7f7f7;
  width: 50%;
  margin-right: 10px;
  padding: 0px 20px;
}

.calciResultArea {
  height: 200px;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  justify-content: center;
  padding: 0px 20px;
  width: 50%;
}

.calciName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.calciResultArea .ant-input[disabled] {
  background: #fff !important;
  color: #000 !important;
  font-size: 30px !important;
}

.colorGreen {
  color: green;
}

.colorRed {
  color: red;
}

.paidButton {
  border-radius: 7px;
  background-color: #038fdd !important;
}

.paidDate {
  display: flex;
  flex-direction: column;
}

.ant-tabs-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown-days {
  height: 36px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
  padding: 10px;
  text-decoration: none;
  outline: none;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  width: 100%;
}

.acc_style {
  border: 1px solid grey;
  color: green;
}

/* leave styles */
.leave_types {
  width: 100%;
  margin-bottom: 1rem;
}

.casual_type {
  width: 100%;
  margin-bottom: 1rem;
}

.casual_type .select_type {
  width: 100% !important;
  padding: 0px !important;
}

.pic {
  display: flex;
  justify-content: space-between;
}

.wid-50 {
  width: 45%;
}

.wid-100 {
  width: 100%;
}

.btn_style {
  /* border: 1px solid grey;
  color: green; */
  /* height: 40px; */
  /* width: 84px; */
  background: #f2fcf5;
  border: 1px solid #60a970;
  border-radius: 4px;
  /* color: white; */
  color: #00751a;
}

.btn_style:hover {
  border: 1px solid #60a970;
  color: #60a970;
}

.btn_in {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #00751a;
}

.foo_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sideHeading {
  text-transform: capitalize;
}

.next {
  /* border: 1px solid #038fdd;
  border-radius: 10px;
  border-radius: 8px;dfdf */
  width: 37px;
  height: 40px;
  background: #ffffff;
  /* border-width: 1px 1px 1px 0px; */
  border-width: 1px;
  /* border-left-width: 0; */
  border-style: solid;
  border-color: #dfe7eb;
  border-radius: 0px 4px 4px 0px;
}

.prev {
  /* border: 1px solid #038fdd;
  transition: 0.5s all; sfdf*/

  /* border-radius: 8px; */
  width: 37px;
  height: 40px;
  background: #ffffff;
  /* border-width: 1px 0px 1px 1px; */
  border-width: 1px;
  /* border-right-width: 0; */
  border-style: solid;
  border-color: #dfe7eb;
  border-radius: 4px 0px 0px 4px;
}

.prev:hover {
  border: 1px solid #038fdd;
}

.next:hover {
  border: 1px solid #038fdd;
}

.counter {
  margin-right: 20px;
  color: #404040;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.arrow {
  border: 0.1px solid #404040;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.tableview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.ant-card-head-title {
  text-transform: capitalize;
}

.prevnext {
  pointer-events: none;
  opacity: 0.7;
}

.container-fluid {
  background: #ffffff;
}

.row {
  /* margin: 38px 20px 30px 0px; */
  background: #ffffff;
  border: 1px solid rgba(225, 225, 225, 0.4);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.dashboard-border {
  width: 0.5px;
  height: 146px;
  border: 1px solid #f6f6f6;
}

.employee-title {
  /* position: relative; */
  /* width: 100%; */
  /* display: flex;
  justify-content: space-between !important;
  align-items: baseline; */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 13px;
  color: #757575;
}

.employee-title-rev {
  margin-top: 4px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 13px;
  color: #757575;
}

.employee-col {
  width: 100%;
  padding: 23px 10px 1px 23px;
  border-right: 1px solid rgba(225, 225, 225, 0.4);
}

.employee-cols {
  width: 100%;
  padding: 23px 10px 1px 23px;
}

.employee-count {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  /* line-height: 24px; */
  color: #303030;
}

.employee-count-rev {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  padding-top: 5px;
  margin-bottom: 21px;
  color: #303030;
}

.employee-years {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 4px;
  color: #4c4c4c;
  padding-bottom: 8px;
}

.employee-percent {
  background: #fdefef;
  border-radius: 30px;
  padding: 7px;

  font-weight: 400;
  font-size: 13px;
  color: #ed5e5e;
}

.employee-percent-success {
  background: rgba(91, 170, 63, 0.1);
  border-radius: 30px;
  padding: 7px;
  /* float: right; */

  font-weight: 400;
  font-size: 13px;
  color: #5baa3f;
}

.employee-col-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-col-1 {
  width: 50%;
  border: 1px solid gray;
}

.dashboard-col-2 {
  width: 50%;
  border: 1px solid gray;
}

.rounded-border {
  border-radius: 6px;
}

.upcome-leave {
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #303030;
  margin: 0;
}

.upcome-view {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  /* color: #038fdd !important; */
  color: gray !important;
}

.upcome-view:hover {
  color: #214757 !important;
}

.dashboard-border-leave {
  width: 0.5px;
  height: 41px;
  border: 1px solid #f3f0f0;
  margin-left: 100px;
}

.dashboard-festival {
  width: 250px;

  font-weight: 500;
  font-size: 13px;
  line-height: 12px;

  color: #000000;
}

.dash-day {
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  color: #757575;
}

.dash-date {
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}

/* .ant-tabs */
/* .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #096dd9;
} */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #214757 !important;
  /* text-shadow: 0 0 0.25px currentColor; */
}

.ant-tabs-tab:hover {
  color: #214757 !important;
}

.ant-tabs-content-holder {
  border-color: #214757 !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #214757 !important;
}

.dashboard-employee-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #303030;
}

.dash-empid {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #757575;
}

.dash-emp-day {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  color: #606060;
  text-align: right;
}

/* @media (max-width: 767px) {
  .dash-emp-day{
  background-color: black !important;}

} */
.ant-spin-dot-item {
  background-color: #214757 !important;
}

.dash-emp-date {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
  text-align: right;
}

.dashboard-graph-col {
  width: 70%;
  /* height: 727px; */
  margin-bottom: 68px;
}

.dashboard-holiday-col {
  width: 30%;
  height: 800px;
}

.dash-holi-day {
  height: 250px;
  border-radius: 6px;
}

.employe-review {
  display: inline-block;
  height: 250px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 100px;
}

.dash-holiday-today {
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #303030;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 28px 20px;
  /* margin: 0px !important; */
}

.dash-holiday-today span {
  /* background: #109cf1; */
  background: #214757;
  border-radius: 2px;
  color: #fff;
  padding: 1px 6px;
}

.dashboard-employee-wfh {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #303030;
}

.dashboard-employee-wfh span {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #757575;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -ms-flex-align: center;
  padding: 30px;
  width: 100%;
  height: 71px;
  background: #f7fafd;
  box-shadow: 0px 2px 3px -1px rgba(17, 17, 17, 0.06);
}

.head-col-2 {
  display: flex;
  align-items: center;
}

.head-col-1 {
  position: relative;
  display: inline-block;
}

.notification:hover {
  filter: brightness(0) saturate(100%) invert(38%) sepia(97%) saturate(1484%)
    hue-rotate(176deg) brightness(93%) contrast(98%);
  cursor: pointer;
}

.notification-box {
  position: absolute;
  /* width: 20px; */
  padding: 5px;
  /* height: 17px; */
  top: -4px;
  left: 10px;
  /* padding: 2px; */
  background-color: #214757;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 9px;

  text-align: center;

  color: #ffffff;
  /* padding: 4px; */
  border-radius: 4px;
}

.right-border {
  width: 2px;
  height: 46px;

  border: 1px solid #dfe7eb;
  margin-right: 26px;
  margin-left: 26px;
}

.head-col-3 {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
}

.profile-pic {
  margin-top: 12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.header-name {
  margin-top: 24px !important;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #333333;
  text-transform: capitalize;
  cursor: pointer;
}

.headers-name {
  margin-top: 24px !important;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #333333;
  /* text-transform: capitalize; */
  cursor: pointer;
}

.header-email {
  margin-top: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #475569;
}

.header {
  margin-left: 16px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19); */
  /* width:1328px; */
  padding: 20px;
  /* position: stricky; */
  /* z-index: 2; */
}

.head-right {
  margin-left: 20px;
  margin-right: 43px;
}

.dropdown_onboard {
  position: relative;
  display: inline-block;
}

.dropdown_onboard:hover .dropdown-content {
  display: block;
}

.dropbtn-head {
  display: flex;
  color: white;
  margin-left: -24px;
  font-size: 16px;
  border: none;
  margin-right: -41px;
}

.dropdown-head {
  position: relative;
  display: inline-block;
}

.dropdown-content-head {
  display: none;
  position: absolute;
  right: -17px;
  background-color: #fff;
  min-width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -127px;
  /* color: black !important; */
}

.dropdown-content-heads {
  /* display: none; */
  position: absolute;
  right: -17px;
  background-color: #fff;
  min-width: 140px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
  margin-left: -127px;
  border: 1px solid #e8e8e8;
  top: 12px;
  border-radius: 4px;
}

.dropdown-content-head a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #214757 !important;
}

.menu-bar-icon {
  cursor: pointer;
  margin-top: -6px;
}
.menu-bar-icons {
  cursor: pointer;
}

.dropdown-content-head a:hover {
  background-color: #f0f0f0;
}

.dropdown-head:hover .dropdown-content-head {
  display: block;
}

.dropdown-content-heads a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  border-bottom: 1px solid #ebebeb !important;
  display: block;
}

.dropdown-content-heads a:last-child {
  border-bottom: none !important;
}

.dropdown-content-heads a:hover {
  background-color: #f0f0f0;
}

.dropdown-head:hover .dropdown-content-heads {
  display: block;
}

.dropdown-dash {
  position: absolute;
  top: 100%;
  left: 0;
  width: 350px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background: #fff;
  margin-left: -301px;
  top: 36px;
  transition: all 0.5s;
  padding: 20px 0;
  overflow-y: auto;
  max-height: 80vh;
}

.tailShadow {
  background-color: transparent;
  /* width: 4px;
  height: 4px;
  position: absolute;
  top: 0px;
  left: 66px; */
  /* z-index: -10; */
  /* box-shadow: 0px 0px 8px 1px black;
  -moz-box-shadow: 0px 0px 8px 1px black;
  -webkit-box-shadow: 0px 0px 8px 1px black; */
}

.tail1 {
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent transparent #919191 transparent;
  position: absolute;
  top: -20px;
  left: 98px;
}

.tail2 {
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  left: 98px;
  top: -20px;
}

.dropdown-noti {
  display: flex;
  justify-content: space-between;
  padding: 3px 27px 5px 23px;
}

.dropdown-noti-name {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.dropdown-noti-clear {
  color: #333;
  cursor: pointer;
  font-size: 17px;
}

.dropdown-noti-clear-disable {
  color: rgb(87, 85, 85);
  cursor: not-allowed;
}

.dropdown-noti-list {
  display: flex;
  align-items: center;
  /* mx-flex-align: center; */
  background-color: #f0f0f0;
  cursor: pointer;
  margin: 8px 10px;
  border-radius: 3px;
  padding: 5px;
}

.noti-admin {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
  color: #222222;
  text-transform: capitalize;
}

.noti-admin-des {
  margin: 0;
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.profile-picture {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #cdcdcd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  font-size: 20px;
  margin-right: 10px;
  font-weight: 500;
}

.dropdown-noti-list-read {
  display: flex;
  align-items: center;
  /* mx-flex-align: center; */
  margin: 8px 10px;
  cursor: pointer;
}

.fun_container {
  max-width: 100%;
  margin-top: 25px;
  padding: 0 20px;
}

.star {
  color: red;
  font-size: 19px;
  font-weight: 400;
  margin-left: 2px;
}

.client-table {
  text-align: left;
  margin: 0;
}

.ant-select-arrow {
  top: 47% !important;
  margin-top: -3px !important;
}

/* .ant-table table>thead>tr>th {
  font-weight: 600;
} */

.ant-input {
  border-radius: 6px !important;
}

/* .ant-select-selector {
  border-radius: 6px !important;
} */

.ant-picker {
  border-radius: 6px !important;
}

.ant-input-group > input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-input-group-addon {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: -0.875rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: transparent;
  background-clip: none;
  border: none;
  border-radius: 0.25rem;
}

.dropdown-toggle::after {
  display: none;
}

.nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
}

.ant-divider {
  margin-top: 0px !important;
}

.dashboard-dropdown-graph {
  width: 163px;
  margin-left: 10px;
}

.dashboard-dropdown-graph.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 3px;
}

.divider {
  margin: 20px 0;
}

.ant-divider {
  margin-top: 20px !important;
}

.newview {
  display: block;
}

.Tab-wrap {
  overflow: hidden;
}

.ant-card-bordered {
  border: none;
}

.ant-card-body {
  padding: 0;
}

/* .ant-layout-content {
  height: none !important;
} */
main .ant-layout-content {
  /* overflow-y: scroll; */
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}

/* table design */

.ant-table-thead {
  width: 1150px;
  height: 59px;

  border-radius: 10px 10px 0px 0px;
}

.ant-table-thead > tr > th {
  background: #f7fafc;
  font-weight: 600;
  font-size: 13px !important;
  line-height: 13px;
  color: #212121;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th {
  font-size: 13px !important;
}

.ant-table-tbody > tr {
  /* width: 1150px; */
  height: 47px;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}

.ant-table-tbody > tr > td {
  text-transform: capitalize !important;
  color: #212121;
  border-bottom: 1px solid rgba(225, 225, 225, 0.4) !important;

  font-weight: 500 !important;
  font-size: 13px;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  padding: 14px 8px !important;
}

.gx-table-responsive {
  width: 100%;
  background: #f7fafc;
  border-bottom: 1px solid #dfe7eb;
  border-radius: 10px 10px 0 0;
}

.ant-picker-content td {
  border-bottom: none !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 9px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 9px;
}

.ant-table-container table > tbody > tr:last-child td:first-child {
  /* border-bottom-left-radius: 9px; */
}

.ant-table-container table > tbody > tr:last-child td:last-child {
  /* border-bottom-right-radius: 9px; */
}

.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 20px;
}

.ant-table {
  border-radius: 10px 10px 0 0;
  /* border-radius: 10px; */
}

.pagination {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px;
}

.serials {
  padding-left: 20px;
}

.ant-card-head {
  border-bottom: none !important;
}

.counter > span {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  height: 15px;
}

.name {
  color: black !important;
}

.exp_tab_border .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.emergency_phone input {
  height: 47px !important;
}

.text_area {
  height: 55px !important;
}

.qk_sidebar {
  /* position: fixed; */
  background: #152e38;
  /* width: 300px; */
  height: 100vh !important;
  /* display: flex; */
  /* justify-content: center;
  top: 0; */
  /* transition: 350ms; */
  z-index: 10;
  /* height: 100%; */
  overflow: auto;
  transition: all 0.5s;
}

.logo-admin-head {
  /* background: yellow; */
  /* height: 80px !important; */
  padding: 21px 50px;
  background: #214757;
  /* z-index: 99; */
  /* margin-left: -15px; */
  transition: all 0.5s;
}

.qk-menu-icons {
  width: 20px;
  height: 20px;
}

.qk_sidebar2 {
  width: 10%;
  flex-shrink: 0;
  background: #152e38;
  height: 100%;
  overflow: auto;
  transition: all 0.5s;
}

.qk-sidemenu {
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 15px 45px;
  list-style: none;
  /* height: 60px; */
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 15px;
}

.qk-sidemenus {
  display: flex;
  color: #e1e9fc;
  justify-content: center;
  align-items: center;
  padding: 15px 45px;
  list-style: none;

  text-decoration: none;
  font-size: 18px;
  margin-bottom: 15px;
}

.qk-sidemenus-small-screen {
  display: flex;
  width: 200px;
  color: #e1e9fc !important;
  align-items: center;
  padding: 10px 12px;
  list-style: none;
  height: auto;
  overflow: auto;
  text-decoration: none;
  font-size: 16px;
}

.qk-sidemenus-small-screen a:hover {
  color: white;
}

.qk-sidemenu-wrapper {
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 80px;
  list-style: none;

  text-decoration: none;
  font-size: 18px;
  margin-bottom: 15px;
  /* transition: all 1.5s; */
}

.qk-sidebar-title {
  font-weight: 400;
  font-size: 15px;

  color: #a1acbc;
}

.qk-sidemenus.qk-sidebar-title-small {
  text-align: left;
}

.qk-sidemenu-icon {
  color: #a1acbc;
}

.qk-sidebar-hover:hover {
  background-color: #29444e;
  transition: all 0.5s;
}

.qk-sidebar-hover:hover span {
  color: #fff !important;
}

.qk-sidemenu .qk-updown:hover {
  color: #fff !important;
}

.qk-updown {
  color: #a1acbc !important;
}

.qk-sidebar-hover:hover img {
  filter: brightness(0) invert(1);
}

.qk-sidebar-active {
  padding: 15px 42px;
  background-color: #0b2129;
  color: #fff !important;
  border-left: 3px solid #fff !important;
}

.qk-sidebar-actives {
  padding: 15px 20px 15px 78px;
  background-color: #0b2129;
  color: #fff !important;
  border-left: 3px solid #fff !important;
}

.qk-sidebar-actives span {
  color: #fff !important;
}

.qk-sidebar-active span {
  color: #fff !important;
}

.qk-sidebar-active-small {
  border-radius: 4px;
  background-color: #0b2129;
  color: #fff !important;
}

.qk-dec-col {
  background-color: #0b2129;
  border-left: 3px solid #fff;
}

.qk-sidebar-active img {
  filter: brightness(0) invert(1);
}

.qk-sidebar-wrapper {
  /* padding: 16px; */
  display: block;
  transition: background_color 0.15s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.toggle-btn {
  color: #fff;
}

.toggle-btns {
  color: #a1acbc;
}

.qk-sidebar-wrapper:hover {
  background-color: #29444e;
  /* transition: all 0.5s; */
  cursor: pointer;
}

.sidebar-content {
  padding-top: 0.25rem;
  height: 0;
  overflow: hidden;
}

.qk-sidebar-wrapper.open .sidebar-content {
  height: auto;
}

.qk-sidemenu-child p {
  padding: 16px 63px;
  width: 100%;
}

.qk-sidemenu-child p:hover {
  background-color: #29444e;
}

.qk-sidemenu-active p {
  background-color: #0b2129;
}

.qk-sidebar-head {
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */
}

.ant-divider {
  margin-top: 0px !important;
}

.admin-table {
  /* margin-bottom: 100px;
  background: #ffffff;
  border: 1px solid #dfe8eb;

  border-radius: 10px; */
  margin-bottom: 100px;
  background: #ffffff;
  border: 1px solid #dfe8eb;
  box-shadow: 0px 1px 3px 1px rgba(17, 17, 17, 0.06);
  border-radius: 10px;
}

.qk_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 21px 50px; */
  background: #214757;
  transition: all 0.5s;
}

.qk_logo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  padding: 25px 37px;
  background: #214757;
  transition: all 0.5s;
}

.admin-logo {
  width: 298px;
  height: 80px !important;
  padding: 0px 31px;
  background: #214757;
  position: absolute;
  z-index: 99;
  transition: all 0.5s;
}

/* .admin-logo img {
  width: 204px;

  height: 85px;
  padding: 18px;
} */

.input-group1.ant-input {
  height: 47px;
  margin-top: -1px;
}

.add-table .ant-table-tbody > tr > td {
  /* border-bottom: none !important; */
}

.admin-dash-board {
  display: flex;
  background: #ffffff;
  border: 1px solid rgba(225, 225, 225, 0.4);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  align-content: stretch;
  width: 100%;
}

.admin-dash {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px;
}

.admin-dash-1 {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 23px 20px 30px;
  -ms-flex-align: center;
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / -15%), 0 6px 20px 0 rgb(0 0 0 / 7%); */
  /* border-bottom: 1px solid rgb(231, 231, 231); */
}

.top-headere {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 23px 20px 30px;
  -ms-flex-align: center;
}

.ad-title {
  line-height: 18px;
  font-weight: 600;
  font-size: 20px;
  /* color: #000000; */
  /* margin: 0 !important; */
  color: #212121;
  margin-bottom: 0 !important;
}

.dec-title {
  line-height: 43px;
  font-weight: 600;
  font-size: 20px;
  /* color: #000000; */
  /* margin: 0 !important; */
  color: #212121;
  margin-bottom: 0 !important;
}

.ad-btn {
  /* width: 72px;
  height: 38px; */
  padding: 12.5px 18px;
  background: #214757;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  border: none;
  color: #ffffff;
}

.add-btn {
  padding: 12.5px 18px;
  background: #214757;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  border: none;
  color: #ffffff;
  width: 105px;
  height: 44px;
  outline: none;
  border: none;
}

.dec-btn {
  padding: 10px 18px;
  background: #214757;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  border: none;
  color: #ffffff;
}

.signup-btn {
  /* width: 72px;
  height: 38px; */
  padding: 12.5px 18px;
  background: #214757;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  border: none;
  color: #ffffff;
}

.dec-btn-secondary {
  border: none;
  outline: none;
  background: transparent;
  padding: 24px;
  font-weight: 500;
  font-size: 15px;
  color: #214757;
}

.secondary_celar {
  padding: 20px;
  font-size: 13px;
}

.ad-main {
  padding: 30px 23px 30px 30px;
}

.add-main {
  padding: 30px 23px 30px 30px;
}

.onboard-detail_main {
  padding: 30px 23px 30px 30px;
}

.ad-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.ad-filter-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-icon {
  cursor: pointer;
  color: #333333;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}

.head-icon {
  /* width: "25px";
  margin-right: "10px"; */
  padding: 27px 16px 24px 0px;
  cursor: pointer;
}

.ad-main-content {
  width: 84%;
  transition: all 0.5s;
}

.header-qk {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.header-qk span {
  font-weight: 500;
  font-size: 18px;
  margin-top: 4px;
  color: #333333;
}

/* .ad-main-admin-content {
  width: "100vw";
  height: "100vh";
  overflow: "hidden";
  display: "flex";
  justify-content: "space-between";
} */
.main-content-full-open {
  width: 95%;
  transition: all 0.5s;
}

.sidebar-min-open {
  width: 100px;
  transition: all 0.5s;
}

.admin-logo2 {
  display: none;
}

.qk-sidebar-title2 {
  display: none;
}

.qk_sidebarWrap {
  width: 100%;
  margin-top: 28px;
  margin-bottom: 84px !important;
}

.sidebar-min-size {
  display: none;
  transition: all 0.5s;
}

.sidebar-big-size {
  display: block;
  transition: all 0.5s;
}

/* .qk-sidebar-wrapper-admin {
  position: absolute;
  margin-left: 250px;
 
} */
.admin-dash-col {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex: 1;
}

.main-content {
  /* margin-bottom: 80px; */
  padding: 30px 23px 30px 30px;
}

.main-content2 {
  padding: 0px 23px 30px 30px;
}

/* .drop-down {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
} */

.drop-down.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

  /* border-radius: 0px !important; */
}

/* .ant-select-selector {
  border-radius: 6px !important;
} */
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.rangepicker.ant-picker {
  border-radius: 4px !important;
  height: 45px !important;
  width: 180px !important;
  padding: 13px 12px !important;
  border: 1px solid #d9d9d9 !important;
  cursor: pointer !important;
}

.rangepicker.ant-picker .ant-picker-input > input::placeholder {
  /* color: #214757 !important; */
  color: #212121 !important;

  font-weight: 500 !important;
  font-size: 13px !important;
}

.anticon {
  cursor: pointer !important;
}

.select-dropdown-menu.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  width: 170px;
  border-radius: 4px !important;
  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px;
}
.select-dropdown-menus.ant-select {
  width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 45px !important;
  padding: 0 11px;
}
/* .select-dropdown-menuss{

} */
.select-dropdown-menu,
.select-dropdown-menus,
.select-dropdown-menuss,
.ant-select-selection-placeholder {
  /* color: #214757 !important; */
  color: #212121 !important;

  font-weight: 500 !important;
  font-size: 13px !important;
}
.select-dropdown-menuss.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  max-width: 100%;
  border-radius: 4px !important;
  height: 50px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px;
}
.select-dropdown-menus.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  max-width: 100%;
  border-radius: 4px !important;
  height: 50px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px;
}

.select-dropdown-menus,
.select-dropdown-menuss,
.ant-select-selection-placeholder {
  /* color: #214757 !important; */
  /* color: #214757 !important; */
  /* color:red !important; */
  font-weight: 500 !important;
  font-size: 13px !important;
}

.select-dropdown-menu-input.ant-input::placeholder {
  /* color: #214757 !important; */
  color: #212121 !important;
}

.select-dropdown-menus.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;

  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px !important;
  width: 150px !important;
}
.select-dropdown-menuss.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;

  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px !important;
  width: 150px !important;
}
.ant-select-item-option-content {
  font-weight: 500 !important;
}

.ant-input-group.ant-input-group-compact > *:last-child {
  font-weight: 500 !important;
}

.select-dropdown-menu-input.ant-input-group.ant-input {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  /* padding: 3px 12px !important; */
  width: 200px !important;
}

.filter-row-col {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.qk-admin-filter-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
}

.filters {
  /* position: "relative";
  display: 'block'; */
  margin-right: 16px;
}

.ad-filter-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input-menu-field.ant-input {
  width: 157px !important;
  border-radius: 4px !important;
  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px !important;
  font-weight: 500 !important;
}

.input-menu-field .ant-input::placeholder {
  /* color: #08a9ee !important; */
  color: #757575 !important;

  font-weight: 500 !important;
  font-size: 13px !important;
}

.commonDatepicker.ant-picker {
  width: 350px;
  height: 47px !important;
  border-radius: 5px !important;
  margin-top: 7px !important;
  /* cursor: pointer !important; */
}

.commonDatepickers.ant-picker {
  width: 100% !important;
  height: 47px !important;
  border-radius: 5px !important;
  margin-top: 7px !important;
  /* cursor: pointer !important; */
}

.ant-calendar-cell {
  cursor: pointer;
}

.ant-calendar-cell:hover {
  pointer-events: none;
  cursor: pointer;
}

.small-screen-bar {
  display: flex !important;
}

.qk-sidemenu-wrappers {
  position: absolute !important;

  margin-left: 230px;
  width: 189px;

  display: flex;
  flex-direction: column;
  background-color: blue;
  color: white !important;
  z-index: 99;
  padding: 12px 10px;
}

.navlink-bar {
  background-color: white;
  padding: 10px 12px;
  position: inherit;
}

.upcoming-leave {
  padding: 0px 24px 24px 24px;
}

.upcoming-leaves {
  position: relative;
  padding: 24px;
}

.small-bar-container {
  display: none;
  position: absolute;
  background-color: #152e38;
  left: 5%;
  z-index: 1;
  text-align: left;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.small-screen-bar:hover .small-bar-container {
  display: block;
}

.small-bar-container a {
  text-align: left;
}

.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 37px !important;
}

.dropdown-menu-bar {
  width: 100px;
  padding: 5px;
}

/* 
.ant-dropdown-menu-title-content {
  color: #212121 !important;
  font-weight: 600 !important;
  font-size: 14px;
  font-size: 13px;
} */
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0 !important;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-dropdown-menu-title-content {
  color: #212121 !important;
  font-weight: 600 !important;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
}

.ant-dropdown-menu-title-content:last-child > option {
  border-bottom: none !important;
}

.main-dropdown {
  background-color: blue;
  margin-top: 30px;
}

.ant-input-number {
  border-radius: 6px !important;
}

.ant-input-number-handler-wrap {
  right: 4px !important;
}

input[type="number"] {
  width: 85px;
  padding: 8px;
  outline: none;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.dropdown-employee-notification {
  /* height: 400px;
  overflow-y: scroll !important; */
}

.dropdown-employee-notification::-webkit-scrollbar {
  display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #214757 !important;
  border-color: #214757 !important;
}

.ant-checkbox-wrapper {
  align-items: center !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox + span {
  /* margin-top: 6px !important; */
  font-weight: 500 !important;
  font-size: 13px;
  color: #212121;
}

.lopcount input {
  width: 75% !important;
  border: 1px solid #d2d2d2;
  padding: 8px;
  outline: none;
  border-radius: 4px;
}

.checkbox {
  margin-left: 2.3px;
}

.lop_title {
  font-weight: 500 !important;
  font-size: 13px;
  color: #212121;
  margin-bottom: 5px;
}

.chart_div {
  /* background: linear-gradient(#e66465, #9198e5); */
  /* background: linear-gradient(#f7fafd, #f1f3f5); */
  width: 100%;
  height: 650px;
  /* padding: 20px; */
}

.ant-radio-checked .ant-radio-inner {
  border-color: #214757 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #214757;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #214757;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #214757;
}

.item_lop {
  color: red;
}

.date-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #d9d9d9; */
  border-radius: 6px;
  height: 45px !important;
  border: 1px solid #d9d9d9 !important;
  /* padding: 6px 12px !important; */
  /* width: 150px !important; */
  margin-right: 16px;
}

/* .filter {
  outline: none;
  border: 1px solid #0000001f;
  border-radius: 7px;
  background-color: white;
  padding: 5px 10px;
  height: 36px;
  width: 136px;
} */

.filters .rangepicker.ant-picker {
  border: none !important;
  height: 35px !important;
  border: none !important;
  padding: 6px 12px !important;
  outline: none !important;
}

/* .filters .rangepicker.ant-picker-input:focus {
  border: none !important;
  outline: none !important;
} */

.filters .ant-picker-focused {
  border-color: white !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
  box-shadow: none !important;
}

.date-field {
  background: #fafafa;
  padding: 10px 15px;
  border-radius: 5px 0px 0px 4px;
  font-weight: 500;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.welcome {
  margin: 0;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 500;
}

.last-login {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(181 176 176);
}

.dashboard-header {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 20px;
}

.dashboard-header-row {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px 20px;
}

.dash-holiday-todays {
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #303030;
  position: sticky;
  top: 0;
  background-color: #fff;
  /* padding: 28px 20px; */
  /* margin: 0px !important; */
}

.dash-holiday-todays span {
  /* background: #109cf1; */
  background: #214757;
  border-radius: 2px;
  color: #fff;
  padding: 1px 6px;
}

.dash_loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.page-limit {
  display: flex;
  width: 93px;
}

.page-limit .ant-input {
  border: none !important;
}

.page-limit Select {
  border: none !important;
}

.pagination-limit {
  color: #353434;
  font-weight: 500;
}

/* .select-dropdown-menuss.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 4px;
  right: 45px;
  bottom: 4px;
  left: 4px;
  border-right: 1px solid #e3e3e3 !important;
} */

.page_limit .ant-select .ant-select-arrow {
  top: 42% !important;
  right: 13px;
}

.page_limit .ant-select {
  height: auto;
  padding: 0;
  border: none !important;
}

.dropdown-list.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 45px !important;
  color: black !important;
  border-radius: 3px solid #e2e2e2 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_limit .ant-select .ant-select-selector .ant-select-selection-search {
  right: 45px;
  height: 30px;
  top: 7px;
  border-right: 3px solid #e2e2e2 !important;
}

.bir_rev_div {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

/* .pagination-footer {
  position: relative;
  height: 50px;

} */

.delete-item-list {
  font-size: "19px";
  font-weight: "600";
  color: "#333333";
}

.invoice_card {
  /* margin: 26px; */
  border-radius: 10px;
  background: #ffffff;
  padding: 0px 40px;
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 20%); */
}

.invoice_addr_logo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 250px;
}

.invoice_comp_addr {
}

.invoice_comp_logo {
  width: 800px;
  /* padding: 0 0 25px 25px; */
}

.invoice_comp_img_upload {
  width: 600px;
  height: 200px;
  border: 2px dashed grey;
  border-radius: 10px;
  float: right;
  position: relative;
}

.invoice_td .ant-input {
  border: none;
  width: 200px !important;
  padding-left: 0px;
}

.invoice_td .ant-input:focus {
  border: none;
}

.invoice_td .ant-input:hover {
  border: none;
}

.invoice_btn {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  cursor: pointer;
}

.invoice_tot {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 40px; */
  margin-right: 7px;
}

.invoice_tot .wrap {
  display: flex;
  width: 500px;
  margin-top: 40px;
}

.invoice_tot .wrapper {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-top: 20px;
}

.invoice_checkbox {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  width: 100%;
}

.invoice_checkbox:first-child {
  margin-bottom: 10px;
}

.invoice_checkbox .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0 !important;
}

.invoice_flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
}

.invoice_flex .ant-checkbox-wrapper {
  padding-bottom: 20px;
}

.invoice_flex .ant-checkbox-wrapper .ant-checkbox + span {
  margin-top: 8px !important;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 15px;
  color: #212121;
  padding-left: 16px;
}

.invoice_taxes {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #9c9c9c;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
}

.default_color {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
}

.invoice_title span {
  color: #9c9c9c !important;
}

.invoice_input {
  justify-content: space-between;
}

.invoice_input .ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 32.333333%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.invoice_table {
  border: 1px solid #dfe8eb;
  box-shadow: 0px 1px 3px 1px rgb(17 17 17 / 6%);
  /* border-radius: 10px; */
}

.invoice_table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 0px;
}

.invoice_table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 0px;
}

.invoice_table .ant-table-tbody > tr > td {
  border-bottom: block;
}

.invoice_address {
  font-size: 17px;
  font-weight: 600;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.merge_file {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* position: relative; */
}

.remove_img {
  border-radius: 50%;
  padding: 7px;
  position: absolute;
  top: 0;
  right: 0;
  background: burlywood;
}

.Drag_title {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
}

.total_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: #333333;
  padding-left: 35px;
}

/* .invoice_table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container table>tbody>tr>td {
  border-bottom: black !important;
} */

.invoice_view {
  background: #f7fafd;
  padding: 20px 4px;
}

.invoice_view_info {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #000000;
}

.invoice_img_con {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice_preview {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #214757;
  padding-left: 8px;
  cursor: pointer;
}

.invoice_block {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 28px 12px;
}

.Invoice_company_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.Invoice_company_addr {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #555555;
  text-transform: capitalize;
  margin: 14px 0 0 0;
}

.Invoice_company_logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 49px !important;
  margin-bottom: 70px;
}

.invoice_select_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.invoice_input_label {
  display: inline-flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #333333;
}

.invoice_addr_edit_block {
  background: #f7fafd;
  border-radius: 12px;
  width: 100%;
  margin: 42px 14px 20px 14px;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.hr {
  border: 1px solid #f7f7f7;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.antd_row {
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 50px;
}

.company_logo_block {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.invoice_img_input {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/*<-------------><-------------><-------------><------------->*/
/* .project-parent {
  padding: 25px;
  font-family: Montserrat;
  margin-bottom: 71px;
} */

.project_inputs {
  width: 100%;
}

.newcard_overall {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newcard_invoice_overall {
  padding: 6px 0px;
  margin-top: 24px;
  display: flex;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}
.newcard {
  width: 23.5%;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding: 26px 16px;
}

.newInvoiceCard {
  width: 23.5%;
  border-radius: 8px;
  padding: 20px 16px;
}

.newInvoiceCard:not(:first-child) {
  border-left: 1px solid #f6f6f6;
}

.card_header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #8d99a9;
}

.card_value {
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
}

.newcard_filter {
  display: flex;
  margin-top: 24px;
}

.search {
  width: 100%;
  background-image: url(../assets/search.svg);
  background-repeat: no-repeat;
  background-position: 11px 16px;
  /* padding: 17px 0px 11px 33px; */
  padding: 15px 0px 14px 33px;
  border-radius: 4px;
  border: none;
  font-size: 13px;
  border: 1px solid #cdcdcd66;
  outline: none;
  font-weight: 500;
}

.search ::placeholder {
  color: #214757;
  margin-bottom: 5px !important;
}

.breadcrumbs_layout {
  display: flex;
}

.firstcrumbs {
  font-weight: 500;
  font-size: 15px;
  color: #757575;
  cursor: pointer;
}

.firstcrumbs:hover {
  color: #757575;
}
/*<-------------><-------------><-------------><------------->*/

.invoice_card_overall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice_each_card {
  width: 23%;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding: 10px 16px;
}

.lowercase {
  text-transform: lowercase;
}

.invoice_each_card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #8d99a9;
}

.invoice_each_card .ant-card-head {
  border-bottom: 3px solid #f7f7f7 !important;
}

.invoice_each_card .ant-card-body {
  margin-top: 20px;
}

.invoice_count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #038fdd !important;
}

.added_class_customer .ant-select-selector {
  width: 285px !important;
  padding-left: 40px !important;
}

.added_class_customer .ant-select-arrow {
  left: 14px;
}

.image_close_btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.image_close_btn span {
  display: flex;
  justify-content: center;
}

/* .invoice_td input:hover {
  background: #fafafa;
} */

.edit_icon {
  position: relative;
}

.edit_btn {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Tax_select_box {
  margin-left: -230px;
  /* margin-top: -7px; */
  width: 14%;
}

.Tax_select_box
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  /* height: 48px !important;
  font-weight: 500 !important;
  padding: 7px 11px !important;
  border-radius: 7px !important; */
}

.table_name_highlight {
  font-weight: 600;
  color: #333333;
}

.Tax_select_box .ant-select-selector .ant-select-selection-item {
  padding-right: 0px !important;
}

.Tax_select_box .ant-select-arrow {
  top: 15px !important;
}

.preview_block {
  position: relative;
}

.preview_show {
}

.invoice_preview_show_modal {
  max-width: 1000px !important;
}

.invoice_preview_show_con {
  margin-top: 24px;
  margin-bottom: 24px;
  /* border: 1px solid #CED4DA; */
  /* border-radius: 4px; */
  padding: 28px 12px;
}

.invoice_input_preview_label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #333333;
  margin-top: 20px;
  text-transform: uppercase;
}

.invoice_input_preview_detail {
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #333333;
}

.preview_show {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.ant-dropdown-menu {
  /* width: 125px !important; */
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 11px 15px;
  border-bottom: 1px solid rgba(225, 225, 225, 0.4) !important;
}

.Tax_Container {
  width: 27%;
  margin-left: auto;
  margin-top: 20px;
  padding-right: 30px;
}

.invoice_tax_amt_title {
  color: #333333;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
}

.invoice_detail_container {
  padding: 20px 40px;
  margin-bottom: 100px;
}

.add_placeholder .ant-select-selector .ant-select-selection-placeholder {
  color: #d9d9d9 !important;
}

.customer-type {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-check label {
  margin: 0 0 0 5px;
}

.customer-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tax-title {
  font-weight: 500;
  font-size: 18px;
  color: #757575;
  line-height: 18px;
  margin-bottom: 36px;
}

.tax-input {
  width: 294px;
  height: 38px;
  border: 1px solid rgba(205, 205, 205, 0.4);
  border-radius: 4px;
  padding: 12px 12px 12px 37px;
}

.tax-input:focus {
  outline: none;
}

.input-field {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.input-field img {
  position: absolute;
  padding: 12px;
  min-width: 40px;
  font-size: 13px;
}

.tax-input::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  /* identical to box height, or 100% */
  font-style: normal;

  color: #214757;
}

.tax-main-drawer .ant-drawer-body {
  flex-grow: 1;
  padding: 0px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

/* .tax-drawer {
  padding: 24px 28px;
} */

.drawer-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 24px 28px;
}

.add-tax {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #333333;
}

.tax-content-field {
  padding: 30px 28px 24px 28px;
}
.input-item-label {
  width: 100%;
}
/* .input-item-label label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 18px;
  color: #212529;
} */
.employee-role {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 18px;
  color: #212529;
}
.tax-label label {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 18px;
  color: #212529;
}

.tax-label-input {
  position: relative;
}
.text-lable-input {
  position: relative;
}
.number-label-input {
  position: relative;
}
.text-label-input input {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 18px;
}

.tax-label-input input {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 18px;
  margin-bottom: 24px;
}

.number-label-input input {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 18px 18px 18px 61px;
}

.tax-label-input textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 24px;
}

.textarea-field textarea {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
}

.tax-label-input input:focus,
textarea:focus {
  outline: none;
}

.text-label-input input:focus {
  outline: none;
}
.textarea-field,
textarea:focus {
  outline: none;
}

.tax-label-input input::placeholder,
textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #878b9b;
}

.text-label-input input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #878b9b;
}

.textarea-field textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #878b9b;
}
.number-label-input input:focus,
textarea:focus {
  outline: none;
}

.number-label-input input::placeholder,
textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #878b9b;
}
.tax-label-input img {
  position: absolute;
  padding: 18px;
  min-width: 40px;
  right: 5px;
  top: 3px;
}

.tax-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 28px 24px 28px;
  /* margin-top: 146px; */
}

.tax-close {
  cursor: pointer;
}

.tax-add-edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

/* .taxSwitch .ant-switch-checked {
  background-color: #CADCE3 !important;
}

.taxSwitch.ant-switch {
  width: 30px !important;

  height: 8px !important;
}

.taxSwitch .ant-switch-handle {
  position: absolute !important;
  top: -7px !important;
  left: 0px !important;
  width: 17.5px !important;
  height: 17.5px !important;
 
} */

.switch-tax {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 8px;
  /* margin: 4px 0px; */
  /* background: #CADCE3;
  border-radius: 8.75px; */
}

.switch-tax input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: -3px;
  bottom: 0;
  background-color: #d9d9d9;

  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 8.75px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #cadce3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #cadce3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  /* border-radius: 34px; */
  border-radius: 8.75px;
}

.slider.round::before {
  /* position: absolute; */
  border-radius: 50%;
  width: 15px;
  height: 15px;
  /* top: -3px; */
  background-color: #b5b5b5;
  border-radius: 8.75px;
  margin-left: 0px;
  margin-top: -3px;
}

input:checked ~ .slider::before {
  border-radius: 50%;
  width: 15px;
  height: 15px;

  margin-left: -9px;
  background-color: #214757;
  border-radius: 8.75px;
  margin-top: -3px;
}

.table_page_wrap {
  border: 1px solid #dfe8eb;
  box-shadow: 0px 1px 3px 1px rgba(17, 17, 17, 0.06);
  border-radius: 10px;
  margin-bottom: 100px;
}

.table-main {
  margin-bottom: 100px;
  margin-top: 24px;
}

.table-response {
  width: 100%;
  border-collapse: collapse;
}

.table-response thead {
  height: 60px;
  border-radius: 10px;
}

.table-response thead tr {
  background: #f7fafc;
}

.table-response th,
td {
  padding: 23px;
  border-bottom: 1px solid #dfe7eb;
  /* border-radius: 10px; */
}

.table-response tr:last-child {
  border-bottom: none;
}

.table-responses th,
td {
  padding: 23px;
  border-bottom: none !important;
}

.table-responses.ant-picker-date-panel .ant-picker-content th {
  width: 100%;
}

.on_hover:hover {
  background-color: #f0f0f0;
}
.tables {
  width: 100%;
}

.tables thead tr {
  background-color: #f7fafc;
}

.tables th,
tables td {
  padding: 23px 10px;
  border-bottom: 1px solid #dfe7eb;
}

.tables td:first-child,
.tables th:first-child {
  padding-left: 20px;
}

.tables th {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.2px;
  color: #65748b;
}

.tables tbody td {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #475569;
  padding: 23px 10px;
}

.table-response th {
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.2px;
  color: #65748b;
}

.table-response tbody td {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #475569;
  padding: 23px;

  /* text-align: center; */
}

.table-employee-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #475569;
}

.table-name {
  font-weight: 600;
  font-size: 14px;
  color: #333333 !important;
}

.table-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.empty-message {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.page-border {
  border-top: 1px solid #ececec;
}

.set_margin {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.tax_invoice_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 24px;
  color: #333333;
}

.invoice_logo_titile_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.invoice_no_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  color: #333333;
}

.invoice_due_dates {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #333333;
  padding-left: 8px;
}

.invoice_number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
}

.flex-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
}

.flex-box {
  display: flex;
  align-items: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
}

.fromborder {
  width: 84%;
  border: 0.805524px solid #cecece;
}

.toborder {
  width: 88%;
  border: 0.805524px solid #cecece;
}

.wid-48 {
  width: 48%;
}

.quadkast_comp_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.quadkast_addr_detail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 6px;
  color: #555555;
  text-transform: capitalize;
}

.place_of_supply {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #555555;
}

.place_of_state {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #333333 !important;
}

.preview-table-wrap {
  margin-top: 15px;
}

.preview-table-responsive .preview_detail_table thead th {
  background: #f7fafd !important;
  color: #333333 !important;
}

.preview_detail_table {
  margin-bottom: 0 !important;
}

.preview-detail-table-wrap {
  margin-top: 30px;
  border: 1px solid #dfe8eb;
  box-shadow: 0px 1px 3px 1px rgb(17 17 17 / 6%);
}

.preview-table-responsive .preview_table thead th {
  font-family: "Montserrat";
  font-style: normal;
  background: #312651;
  color: #ffffff;
  padding: 16px 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  border: none !important;
}

.preview-table-responsive .preview_table tbody tr td {
  vertical-align: inherit;
  padding: 16px 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  border-bottom: 0.651458px solid #e0e0e0 !important;
}

.preview_item_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.preview_item_desc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 30px !important;
  color: #555555;
}

.preview_item_common {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
  text-align: end;
}

.Notes_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
}

.Notes_desc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  overflow-wrap: break-word;
}

.sub_amount_title {
  color: #555555;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.sub_amount {
  color: #333333;
  font-size: 14px;
  line-height: 14px;
}

.Discount_title {
  color: #555555;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.Discount_amount {
  color: #333333;
  font-size: 14px;
  line-height: 14px;
}

.final_amt_title {
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.final_amount {
  color: #333333;
  font-size: 14px;
  line-height: 14px;
}

.Tax_name_title {
  color: #555555;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.Taxes_amount {
  color: #333333;
  font-size: 14px;
  line-height: 14px;
}

.margin_bottom_10 {
  margin-bottom: 10px;
}

.quadkast_gst {
  font-weight: 600;
  color: #333333 !important;
}

.client_gst {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  /* padding-left: 20px; */
}

.tax_list_each {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #555555;
}

.final_total_div {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px !important;
}

.Total_calc_wrap {
  padding-right: 20px;
}

.total_title {
  color: #555555;
}

.invoice_sub_amount {
  color: #555555;
}

.payment_tab_flex {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-top: 20px;
}

.Taxlist_map {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.filter-date-to {
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.select-dropdown-menu-input.ant-input:hover {
  border-color: #d9d9d9 !important;
  border-right-width: 1px !important;
}

.input-number-item {
  position: absolute;
  left: 1px;
  top: 1px;
  background: #fafafa;
  /* right: 10px; */
  padding: 13px;
  border-right: 1px solid #d3d1d1;
  border-radius: 5px 0px 0px 4px;
}
.onboard-dates.ant-picker {
  border-radius: 4px !important;
  height: 49px !important;
  width: 100% !important;
  padding: 13px 12px !important;
  border: 1px solid #d9d9d9 !important;
  cursor: pointer !important;
}
.onboard-dates.ant-picker-focused {
  border-color: #d9d9d9 !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
  box-shadow: none !important;
}

.ant-picker {
  border-radius: 4px !important;
  height: 49px !important;
  width: 100% !important;
  padding: 13px 12px !important;
  border: 1px solid #d9d9d9 !important;
  cursor: pointer !important;
}
.ant-picker-focused {
  border-color: #d9d9d9 !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 2px rgba(49, 139, 54, 0.342) !important;
  box-shadow: none !important;
}
.interview-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 18px;
  color: #212529;
}
.invoice_Datepickers {
  width: 50% !important;
  height: 47px !important;
  border-radius: 5px !important;
}
.select-dropdown-menus-access.ant-select {
  width: 100%;
}
.select-dropdown-menus-access.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  max-width: 100% !important;
  border-radius: 4px !important;
  height: 50px !important;
  border: 1px solid #d9d9d9 !important;
  padding: 6px 12px;
}

.btn_loader {
  padding: 7px 32px;
  background: #214757;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.btn_loader .spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.addEdit_loader {
  padding: 0px 24px;
  background: #214757;
  color: #ffffff;
  cursor: pointer;
}

.addEdit_loader .spinner-border {
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.signup-block {
  display: flex;
  justify-content: end;
}

.action-menu-bar {
  margin: 0px;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ededed;
}
.dropdown-content-heads p:hover {
  background-color: #f7f7f7;
}
.hypen {
  padding: 0 3px 0 3px;
}

.doc-date.ant-picker {
  border-radius: 4px !important;
  height: 45px !important;
  width: 304px !important;
  padding: 13px 12px !important;
  border: 1px solid #d9d9d9 !important;
  cursor: pointer !important;
}

.manage-role-name-input {
  position: "relative";
  font-weight: "500";
  height: "47px";
  width: 300px;
  margin-bottom: "60px";
}
.long_addr_desc {
  width: 80%;
  line-height: 26px;
  margin-bottom: 0.6rem !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #ced4da !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}
.ant-input:hover {
  border-color: #ced4da !important;
  border-right-width: 1px !important;
}
.textarea-input {
  height: 250px;
}
.deletemodal {
  font-size: "16px";
  font-weight: "500";
  color: "#757575";
  line-height: "24px";
  margin-bottom: "10px";
}
.cursor-pointer {
  cursor: pointer;
}
.col-describe {
  max-width: 33.33%;
}
.disable-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 18px;
  color: gray;
}
.block-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 18px;
  color: #212529;
}
.input-item-label .ant-select-selection-placeholder {
  color: gray !important;
}
.leave-page {
  width: 300px;
  padding: 0px 23px 12px 30px;
}
.leave-col {
  font-weight: 500;
  color: #214757;
  border-bottom: 2px solid #214757;
  cursor: pointer;
}
.month-col {
  font-weight: 500;
  color: #9b9d9e;
  cursor: pointer;
}
.green {
  color: #25b645;
}
.onboard-color {
  color: #214757;
}
.red {
  color: red;
}
.blue {
  color: #038fdd;
}
.yellow {
  color: #f8b146;
}
.orange {
  color: orange;
}
.purple {
  color: #531253;
}
thead > tr:first-child > th:first-child {
  border-top-left-radius: 10px;
}
thead > tr:last-child > th:last-child {
  border-top-right-radius: 10px;
}
.unpaid-color {
  color: #37509c;
}
.m-right {
  margin-right: 5px;
  font-size: 14px;
}
.status-click {
  cursor: pointer;
}
.xp:hover {
}

.custom-disabled-checkbox .ant-checkbox-inner {
  background-color: #f5f5f5 !important; /* Change the background color of the disabled checkbox */
}

.custom-disabled-checkbox .ant-checkbox-checked::after {
  border: 1px solid #1890ff !important; /* Change the border color of the checked checkbox */
}

.custom-disabled-checkbox .ant-checkbox-input[disabled] + .ant-checkbox-inner {
  border-color: #d9d9d9 !important; /* Change the border color of the disabled checkbox */
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-message .anticon {
  padding-bottom: 3px;
}

.noWrap {
  white-space: nowrap;
}

.leave-field {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}
.leave-field div {
  width: 100%;
}
.leave-from {
  margin-right: 20px;
}
.leave-input-field {
  padding: 13px 10px;
  outline: none;
  border: 1px solid #eeeeee;
  width: 100%;
  border-radius: 5px;
}

.show-employee-list {
  position: absolute;
  z-index: 1;
  background: white;
  width: 91%;
  height:fit-content;
  max-height: 300px;
  border: 1px solid #eeeeee;
  /* margin: 13px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  overflow: auto;
}
.show-employee-list p{
  margin: 0;
  padding: 10px;
  cursor: pointer;
}
.show-employee-list p:hover{
  background-color: #eeeeee;
}
.emp-name{
  color:#979797;
  margin-right:5px;
  font-weight: 500;
}
.emp-id{
  color: #262626;
  margin-left: 5px;
  font-weight: 500;
}

.checkbox-align{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-6{
  margin-top: 6px;
}

.cursor-pointer{
  cursor: pointer;
}
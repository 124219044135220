.wi-120 {
  width: 120px;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-cen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-rw {
  display: flex !important;
  align-items: center;
}

.flex-r {
  display: flex !important;
  align-items: center;
}

.flex-rw-sb {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.flex-rw-e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.text-u {
  text-transform: uppercase;
}

.text-c {
  text-transform: capitalize;
}

.text-al-left {
  text-align: left;
}

.text-al-right {
  text-align: right;
}

.link-btn {
  color: #1890ff;
  cursor: pointer;
}

.mb-30 {
  margin-bottom: 3rem !important;
}

.mb-20 {
  margin-bottom: 2rem !important;
}
.mb-50 {
  margin-bottom: 5rem !important;
}

.mb-5e {
  margin-bottom: 0.5em;
}

.mb-3 {
  margin-bottom: 0.3rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mr-30 {
  margin-right: 3rem !important;
}

.mr-20 {
  margin-right: 2rem !important;
}

.mr-10 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 0.5rem;
}

.mr-child-20 {
  &:not(:last-child) {
    margin-right: 2rem;
  }

  div {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.mr-child-10 {
  &:not(:last-child) {
    margin-right: 2rem;
  }

  div {
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.three-dots {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.disabled {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
}

.bg-color {
  background-color: $color-bg;
}

/* .super .ant-table-tbody > tr > td {
    border-bottom:0px !important
}
.ant-table-small .ant-table-thead > tr > th{
    background-color: white !important;
} */

.border-check {
  padding: 9px 15px 9px 15px;
  border-radius: 24px;
  background-color: #fafafa;
}

.profile-picture_new {
  width: 34px;
  height: 34px;
  cursor: pointer;
  background-color: #cdcdcd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

/* .button-list button {
    border: none !important;
  } */
.anticon svg {
  font-size: 14px;
  /* margin-bottom: 7px; */
}

.table_profile {
  display: flex;
  align-items: center;
}

.profile_0 {
  background-color: #ffa7fc;
}

.profile_1 {
  background-color: #5aeecb;
}

.profile_2 {
  background-color: #a4e68c;
}

.profile_3 {
  background-color: #7196e8;
}

.profile_lot {
  background-color: #ffa8a7;
}

.drawer_title {
  font-size: 15px;
  font-weight: 500;
}

.roles .ant-select-selector {
  height: 45px !important;
}

.ant-select-arrow .anticon>svg {
  height: 14px;
  width: 8px;
}

.roles .ant-select-selection-item {
  top: 18% !important;
}

/* .ant-table.ant-table-middle .ant-table-tbody > tr > td{
    padding: 12px 33px !important;
  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th{
    padding: 12px 30px !important;
  } */
/* .role_button{
    background-color: #214757;
    width: 103px;
    height:50px;
    border-radius: 5px;
    box-shadow: 0 2px 0 ;
    color: aliceblue;
    border: 1px solid transparent;
  } */
.role_color {
  background-color: white !important;
  color: #214757 !important;
  border: 1px solid black;
  box-shadow: none;
}

.new_button {
  /* width: 60px !important; */
  height: 40px !important;
}

/* .ant-select-selector{
    height: 5rem !important;
  } */
/* 
  .employees .ant-select-selection-item{
    width: 13rem !important;
    border-radius: 2rem;
    height: 3rem !important;
    background-color:  #214757;
  }

  .employees .ant-select-selection-item-content {
 
    color: white !important;
    padding: 0.3rem !important
  }

  .employees .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.5em !important;
    color: white !important;
    padding-left: 1em !important

  } */
/* 
  .role_modal .ant-modal-footer {

    border-top: none !important;
    padding-left: 85px !important;
justify-content: none !important;
  } */
/* .role_modal .ant-modal-body{
    text-align: center !important;
  } */

/* .role_modal .ant-modal-body{
    padding: 24px 24px 0 24px;
  } */
/* 
  .ant-table-thead>tr>th {
    text-transform: none !important;

  } */
.roles button {
  /* width: 110px !important; */
  padding: 9px 26px !important;
  border-radius: 7px !important;
}

.role button {
  /* width: 145px !important; */
  padding: 9px 26px !important;
  border-radius: 7px !important;
}

.oksd.ant-input-affix-wrapper {
  background-color: #f7fafc !important;
  border: none;
}

.ant-input-affix-wrapper>input.ant-input {
  background-color: #f7fafc !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00751a;
}

.ant-card-head {
  padding: 0;
}

.css-1rhbuit-multiValue {
  /* height: 4rem !important; */
  /* width: 30% !important; */
  border-radius: 24px !important;
  margin: 0.5rem 0 0.3rem 0.3rem !important;
  background-color: #214757 !important;
  color: white !important;
  padding: 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
  font-size: 13px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-12jo7m5 {
  color: white !important;
  font-size: 100% !important;
  /* width: 91% !important; */
  padding: 0 !important;
  padding-left: 10px !important;
}

.anticon svg {
  /* font-size: 18px !important; */
}

.role_table {
  border: 1px solid #dfe7eb;
  border-radius: 10px;
}

.super .ant-table.ant-table-small .ant-table-thead>tr>th {
  background-color: white;
}

.edit_table .ant-table-thead>tr>th {
  background: #f7fafc !important;
  border-radius: none !important;
}

.role_delete .ant-modal-footer {
  justify-content: flex-start !important;
  padding-left: none !important;
}

.create_role {
  padding-left: 40px 40px 40px 30px !important;
}

.super .ant-table-tbody>tr>td {
  border-bottom: none !important;
}

.css-g1d714-ValueContainer {
  min-height: 43px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.edit_table .ant-table-container table>thead>tr:first-child th:first-child {
  padding-left: 10px !important;
}

.create_table .ant-table-container table>thead>tr:first-child th:first-child {
  padding-left: 10px !important;
}

.create_table .ant-table.ant-table-small .ant-table-tbody>tr>td {
  padding: 8px 8px 8px 10px !important;
}

.edit_table .ant-table.ant-table-small .ant-table-tbody>tr>td {
  padding: 8px 8px 8px 10px !important;
}

.role_modal .ant-modal-footer {
  background-color: #fafafa !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;
  justify-content: space-evenly !important;
  padding-left: 230px !important;
}

.roles_table .ant-table-small .ant-table-thead>tr>th {
  width: 20% !important;
}

.role-table {
  width: 100%;
  margin: 20px 0 50px 0px;
}

.role-table td {
  padding: 10px 0;
}

.role-text {
  font-weight: 500;
  font-size: 17px;
  /* line-height: 18px; */
  color: #303030;
}

.sub-role-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #757575;
}

.sub-role-title span {
  color: #303030;
  /* margin-left: 27px; */
  font-size: 15px;
  font-weight: 400;
}

.role-header {
  border-bottom: 1px solid #dedede80;
}

.role-header th {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
  padding: 0 0 14px 0;
}

.role-icon {
  margin-left: 10px;
}

/* .role-td {
  text-align: center;
} */

.role-td .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-left: 23px !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.sm__home {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: #fff;
}

.sm__select--store {
  min-width: 20rem;

  @media only screen and (max-width: 600px) {
    min-width: auto !important;
  }
}

.sm__status {
  @media only screen and (max-width: 600px) {
    margin-right: -3px;
  }
  button {
    margin-right: 25px;
    @media only screen and (max-width: 600px) {
      margin-right: 10px;
    }
  }
}

.secondary_color {
  padding: 8px 17PX;
  font-size: 14px;
  color: #214757;
  font-weight: 600;
  border: none;
  outline: none;
  background: transparent;
}

.ad-btn {
  width: 105px;
  height: 44px;

  // line-height: 16px;
  outline: none;
  border: none;
}
.ad-btn-secondary {
  border: none;
  outline: none;
  background: transparent;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  // line-height: 13px;
  color: #214757;
}
.primary_color {
  background: #214757;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 8px 17px !important;
}

.sm__btn--primary {
  border: none;
  background-color: $color-accent;
  color: $color-bg;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: rgba($color-accent, 0.9);
    color: $color-bg;
  }

  &:hover {
    background-color: rgba($color-accent, 0.9);
    color: $color-bg;

    &:disabled {
      background-color: rgba($color-accent, 0.9);
      color: $color-bg;
    }
  }

  &:active {
    background-color: $color-accent;
    color: $color-bg;

    &:disabled {
      background-color: rgba($color-accent, 0.9);
      color: $color-bg;
    }
  }

  &:focus {
    background-color: $color-accent;
    color: $color-bg;

    &:disabled {
      background-color: rgba($color-accent, 0.9);
      color: $color-bg;
    }
  }
}

.sm__login {
  width: 100vw;
  height: 100vh;
  background: rgba(#606060, .2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px rgba(210, 209, 215, 0.15);

  // @media only screen and (max-width: 600px) {
  //   font-size: 14px !important;
  // }

  &__input {
    // @media only screen and (max-width: 600px) {
    //   font-size: 14px !important;
    // }
    &--box {
      @extend .flex-r;
      width: 100%;
      padding: .7rem;
      border: 1px solid #fff;
      background: #fff;
      border-radius: 5px;
    }

    &--text {
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid #eee;
    }

    &--field {
      width: 100%;
      border: none;
      background-color: transparent;
      outline: none !important;
    }

    &--otp {
      display: inline-block;
      width: 4rem !important;
      height: 3rem;
      margin-right: 2rem;
      border-bottom: 1px solid #ccc;
      border-top: none;
      border-left: none;
      border-right: none;
      outline: none !important;
    }
  }

  &--box {
    display: flex;
    flex-direction: column;
    background: #fff;
    // filter:  drop-shadow(0px 20px 10px rgba(0, 0, 0, .1));
    box-shadow: 0px 5px 10px rgba(210, 209, 215, 0.15);
    width: 45rem;
    border-radius: 10px;
    padding: 2.5rem;

    @media only screen and (max-width: 600px) {
      width: 80%;
    }
  }

  &--img {
    width: 12rem;
    height: 12rem;
    display: block;
    margin: 0 auto 6rem auto;
  }

  &--title {
    margin-bottom: 3rem;
    font-weight: 600;
    font-size: 2.2rem;

    // @media only screen and (max-width: 600px) {
    //   font-size: 1rem !important;
    // }
  }

  &__btn {
    margin-top: 5rem !important; 
    font-weight: 600;
    width: 100%;
    margin: 0 auto;
  }
}

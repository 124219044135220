.map-modal {
  width: 90vw !important;
  height: 500px !important;
  top: 1rem !important;

  .ant-modal-content {
    width: 100% !important;
    .ant-modal-body {
      width: 100% !important;
      height: 440px !important;
      padding: 0px;
      position: relative;
    }
  }
}

.sm__modal {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 300px;

  ul {
    background-color: #fff;
    list-style: none;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    max-height: 250px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    li {
      border-bottom: 1px solid #eee;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
      }
    }
  }
}

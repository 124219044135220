.sm__popover {
  p {
    cursor: pointer;
    margin: 0 -16px 10px -16px;
    padding: 0 16px;
    user-select: none;

    &:hover {
      background-color: #f4f4f4;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.sm__upload {
  position: relative;
  width: 10rem;
  height: 10rem;
  margin-right: 3rem;
  display: block;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 5rem;
    height: 5rem;
  }

  &--delete {
    position: absolute;
    top: -17px;
    right: -18px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    color: #fff;
    font-size: 1.5rem;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
  }

  &--btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &--img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    overflow: hidden;
    z-index: 1;

    img {
      width: 90%;
      height: 90%;
    }
  }
}

.sm__hide-s3 {
  opacity: 0;
}

.sm__s3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  cursor: pointer;
}
